/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    angular.module('MailProcessing').config([
        '$stateProvider',
        '$urlRouterProvider',
        (
            $stateProvider: ng.ui.IStateProvider,
            $urlRouterProvider: ng.ui.IUrlRouterProvider
        ) => {
            $urlRouterProvider.when(
                '/dashboard/mail/overzicht/:id',
                '/dashboard/mail/overzicht/:id/inhoud'
            );

            $stateProvider.state('dashboard.mail.overview.details', {
                url: '/:id',
                data: {
                    pageTitle: 'Email details',
                    authorize: ps => ps.handleIncomingMails
                },
                views: {
                    'details@dashboard.mail': {
                        template:
                            '<mail-processing-details state="state$ | async:this"></mail-processing-details>',
                        controller: Umbrella.createComponentRouteController<
                            Umbrella.MailProcessing.MailProcessingState,
                            MailProcessingDetailsComponentState
                        >('MailProcessingStore', s => ({
                            details: s && s.details,
                            registered: s && s.registered
                        }))
                    }
                },
                onEnter: [
                    '$stateParams',
                    'MailProcessingService',
                    (
                        $stateParams: ng.ui.IStateParamsService,
                        mailProcessingService: MailProcessingService
                    ) => {
                        mailProcessingService.selectMail($stateParams.id);
                    }
                ]
            });
        }
    ]);
}
