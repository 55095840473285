/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Contracts {
    @Service('CustomerService', 'UnitContractJitService')
    @Inject('UnitCardStore', 'UnitContractHub', 'ToastMessageService', 'UnitCardService')
    export class UnitContractJitService {
        private message: any;
        constructor(
            store: UnitCardStore,
            private unitContractHub: IUnitContractHubProxy,
            private toastMessageService: ToastMessageService,
            private unitCardService: UnitCardService
        ) {
            const selectUnitId = (state: UnitCardState) => state && state.id;

            const containsContractId = (s: UnitCardState, contractId) => {

                if (s && s.contractInfo && s.contractInfo.contracts && s.contractInfo.contracts.items && s.contractInfo.contracts.items) {
                    return s.contractInfo.contracts.items.some(x => x.id === contractId);
                }

                return false;
            };

            unitContractHubOnUpdated$
                .filter(unitId => {
                    return selectUnitId(store.getState()) === unitId;
                })
                .subscribe(unitId => {
                    if (store.getState().contractInfo) unitCardService.loadContracts(undefined, undefined, true);

                    this.clearToastMessage();
                });

            unitContractHubOnContractUpdated$
                .filter(contractId => {
                    return containsContractId(store.getState(), contractId);
                })
                .subscribe(contractId => {
                    unitCardService.loadContracts(undefined, undefined, true);
                });

            unitContractHubOnStarted$
                .filter(unitId => {
                    return selectUnitId(store.getState()) === unitId;
                })
                .subscribe(model => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Contractgegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectUnitId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(unitId => this.triggerJitUpdate(unitId));

            store.event$.where(e => e.type === 'UnitCardUnloadedEvent').subscribe(_ => this.clearToastMessage());
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }

        private triggerJitUpdate(unitId: System.Guid) {
            this.clearToastMessage();

            if (unitId)
                this.unitContractHub.server.startJitUpdate(unitId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie contractgegevens mislukt');
                });
        }
    }
}
