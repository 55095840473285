namespace Umbrella.MailProcessing {
    import Guid = System.Guid;
    import IResource = ng.resource.IResource;

    export interface MailProcessingReplyDraftResource {
        get(params: {
            operatorId: Guid;
            replyToMailId: Guid;
        }): ng.resource.IResource<MailReplyDraft>;
        save(model: MailReplyDraft): any;
        delete(params: { replyToMailId: Guid }): any;
    }

    angular
        .module('MailProcessing')
        .service('MailProcessingReplyDraftResource', [
            '$resource',
            $resource =>
                $resource(
                    '/api/v1/mailHandlingReplyDraft/:action/:operatorId/:replyToMailId',
                    {},
                    {
                        get: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                action: '',
                                operatorId: 'operatorId',
                                replyToMailId: 'replyToMailId'
                            }
                        },
                        save: {
                            method: 'PUT',
                            isArray: false,
                            params: { action: 'save' }
                        },
                        delete: {
                            method: 'DELETE',
                            isArray: false,
                            params: {
                                action: 'delete',
                                operatorId: '',
                                replyToMailId: 'replyToMailId'
                            }
                        }
                    }
                )
        ]);
}
