/// <reference path="../../../dashboard.mail.routeconfig.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingStore = Umbrella.MailProcessing.MailProcessingStore;
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;
    import MailConversation = Umbrella.MailProcessing.MailModel;
    import DashboardMailList = Umbrella.MailProcessing.DashboardMailList;

    export interface MailProcessingListComponentState {
        list: DashboardMailList;
        mailsTotal: number;
        enabled: boolean;
        selectedMailId: string;
    }

    @Component('MailProcessing', {
        selector: 'mail-processing-list',
        templateUrl:
            '/MailProcessing/_Overview/_List/MailProcessingListComponent/MailProcessingList.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        'MailProcessingService',
        'MailProcessingStore',
        '$stateParams',
        '$location'
    )
    class MailProcessingListComponent {
        public state: MailProcessingListComponentState;
        public page = this.$stateParams.page || 0;

        constructor(
            private mailService: MailProcessingService,
            private mailStore: MailProcessingStore,
            private $stateParams: MailHandlingOverviewStateParams,
            private $location: ng.ILocationService
        ) {}

        public async requestMoreMails(resultsPerPage: number) {
            if (!this.state) {
                return;
            }
            return await this.mailService.getDashboardMails(resultsPerPage);
        }

        public pageChanged(newPage: number) {
            this.page = newPage;
            this.$location.search({ page: this.page });
        }
    }
}
