/// <reference path="Config/Config.ts" />
/// <reference path="Window.d.ts" />

namespace Umbrella {
    export interface SessionState {
        user: Umbrella.Modules.Colleagues.SessionColleagueModel;
        config: any;
        accessToken: string;
        error: Error;
    }

    export const session$ = new Rx.BehaviorSubject<SessionState>({
        user: null,
        accessToken: null,
        config: null,
        error: null
    });    

    function startSession(url: string): void {
        $.ajax(url, {
            //method: 'get',
            //mode: 'cors',
            dataType: 'json',
            //credentials: 'include',
            cache: false,
            success: x => session$.onNext(x),
            error: xhr => {
                switch (xhr.status) {
                    case 0:
                        window.location.assign(Config.makeAbsoluteApiUrl('go/' + window.location.hash.substr(2)));
                        break;
                    case 500:
                        session$.onNext({
                            user: null,
                            accessToken: null,
                            config: null,
                            error: {
                                name: 'NotAuthorized',
                                message: '',
                                stack: ''
                            }
                        });
                        break;
                }
            }
        });
    }

    Config.configStore.state$
        .map(state => state && state.config)
        .skipWhile(cfg => !cfg)
        .take(1)
        .subscribe(cfg => startSession(cfg.api + 'api/v1/system/session'));

    session$.subscribe(session => {
        window.user = session.user;
        window.config = session.config;
        window.session = session;
    });

    export function isDevModeOrVersionAtLeast(major: number, minor: number, patch: number) {
        if (window.config.configuration.toLowerCase() == 'debug') return true;

        if (
            window.config.version.major >= major &&
            window.config.version.minor >= minor &&
            window.config.version.patch >= patch
        )
            return true;

        return false;
    }

    export function startNewSessionWhenTokenHasExpired(): void {
        startSession(Config.makeAbsoluteApiUrl('api/v1/system/session'));
    }

    export function whenAuthenticatedKccChatOperator(onReady: () => void) {
        const isAuthenticatedKccChatOperator = (sessionState: SessionState) => {
            return (
                sessionState &&
                sessionState.accessToken &&
                sessionState.user &&
                sessionState.user.permissions &&
                sessionState.user.permissions.chatOperator &&
                sessionState.user.permissions.viewChatConversation
            );
        };
        session$
            .filter(isAuthenticatedKccChatOperator)
            .take(1)
            .subscribe(_ => {
                onReady();
            });
    }
}
