/// <reference path="../mailprocessing.module.d.ts"/>

namespace Umbrella.MailProcessing {
    import CreateCustomerModel = Umbrella.Modules.Customers.CreateCustomerModel;

    @Component('MailProcessing', {
        selector: 'mail-processing-bar',
        templateUrl: '/MailProcessing/MailProcessingBarComponent/MailProcessingBar.html',
        bindings: {
            mailProcessingInfo: '<'
        }
    })
    @Inject('$state', 'MailProcessingService', 'CreateDialog', '$mdDialog', 'EmptyGuid')
    export class MailProcessingBarComponent {
        private personObserver: Rx.IDisposable;

        public person: PersonModel;
        public mailProcessingInfo: Umbrella.MailProcessing.MailProcessingState;

        constructor(
            private $state: angular.ui.IStateService,
            private mailService: MailProcessingService,
            private createDialog: any,
            private $mdDialog,
            private emptyGuid: System.Guid
        ) {
            this.personObserver = CustomerService.CustomerCard.customerCardStore.state$
                .map(s => s && s.personal && s.personal.person && s.personal.person)
                .filter(person => person !== null)
                .distinctUntilChanged()
                .subscribe(person => {
                    this.person = person;
                });
        }

        public resetSelectedMailStatus() {
            if (
                this.mailProcessingInfo &&
                this.mailProcessingInfo.pickedMails &&
                this.mailProcessingInfo.pickedMails.length > 0
            ) {
                const mailId = this.mailProcessingInfo.pickedMails[0].externalId;

                this.mailService.resetMailStatus(mailId);
            }
        }

        private openMailPreviewPopup() {
            const mail = this.mailProcessingInfo.pickedMails[0];
            this.createDialog(
                '/MailProcessing/MailProcessingPreviewModal/MailProcessingPreviewModal.html',
                {
                    id: 'chatConversationPopup',
                    modalClass: 'popup green popup_800'
                },
                { mail }
            );
        }

        linkCustomer(mail: MailModel, person: { id: System.Guid; name: string }) {
            this.mailService.linkConversation(mail.externalId, person.id, person.name, '');
        }

        unlinkCustomer(mail: MailModel) {
            this.mailService.unlinkConversation(mail.externalId);
        }

        toggleMenu(mail: MailModel, $event) {
            $event.stopPropagation();

            this.mailService.mailBarMenuEvent(mail.externalId, !mail.isMenuOpened);
        }

        createCustomer(mail: MailModel) {
            const person: CreateCustomerModel = {
                customerId: this.emptyGuid,
                email: mail.from.mailAddress,
                firstName: mail.from.displayName.split(' ')[0],
                gender: null,
                insertion: '',
                lastName: mail.from.displayName
                    .split(' ')
                    .slice(1)
                    .join(' ')
                    .trim(),
                phoneNumber: '',
                externalId: null,
                agentInfoId: this.emptyGuid
            };

            this.$mdDialog
                .show({
                    template: '<relation-popup person="person"></relation-popup>',
                    targetEvent: null,
                    clickOutsideToClose: false,
                    preserveScope: true,
                    locals: { person },
                    controller: [
                        '$scope',
                        'person',
                        ($scope, person) => {
                            $scope.person = person;
                        }
                    ]
                })
                .then(customer => {
                    if (customer) this.mailService.linkConversation(mail.externalId, customer.personId, customer.name, '');
                });
        }

        closeMenu = (mail: MailModel) => {
            if (mail && mail.isMenuOpened) {
                this.mailService.mailBarMenuEvent(mail.externalId, false);
            }
        };

        public $onDestroy() {
            if (this.personObserver) {
                this.personObserver.dispose();
                this.personObserver = null;
            }
        }
    }
}
