﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    billHub: Umbrella.CustomerService.CustomerCard.Bills.IBillHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard.Bills {
    import Detailed = Modules.Payments.Billing.BillModel.Detailed;

    export interface IBillHubProxy extends HubProxy {
        client: IBillHubClient;
        server: IBillHubServer;
    }

    export const billHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const billHubOnStarted$ = new Rx.Subject<System.Guid>();

    export interface IBillHubClient {
        updated(customerOrPersonId: System.Guid);
        started(customerId: System.Guid);
    }

    export interface IBillHubServer {
        startJitUpdate(customerId: System.Guid);
    }

    angular
        .module('CustomerService')
        .factory('BillHub', () => $.connection.billHub);

    Modules.onSignalRInitialized(() => {
        $.connection.billHub.client.updated = customerOrPersonId => {
            billHubOnUpdated$.onNext(customerOrPersonId);
        };

        $.connection.billHub.client.started = customerId => {
            billHubOnStarted$.onNext(customerId);
        };
    });
}
