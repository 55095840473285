namespace Umbrella {
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import KnowledgebaseSuggestionsInput = Umbrella.CustomerService.KnowledgebaseSuggestionsInput;

    interface OnSelectedParam {
        faq: FaqModel;
        tag: ThesaurusTagModel;
    }

    @Component('Umbrella', {
        selector: 'select-faq',
        templateUrl: '/MailProcessing/SelectFaqComponent/SelectFaq.html',
        bindings: {
            searchQuery: '<',
            suggestionsQuery: '<',
            onSelected: '&'
        }
    })
    @Inject()
    export class SelectFaqComponent {
        public searchQuery: string;
        public suggestionsQuery: KnowledgebaseSuggestionsInput;
        public onSelected: (params: OnSelectedParam) => void;
        public dropdownVisible: boolean = false;
        public selectedFaq: FaqModel;
        public faqActions: any;

        $onInit() {
            this.setFaqActionDefinitions();
        }

        public toggleDropdown(shouldClose = false) {
            if (shouldClose) {
                this.dropdownVisible = false;
                return;
            }
            this.dropdownVisible = true;
        }

        public clearSelection() {
            this.select(null, null);
        }

        private select(faq: FaqModel, tag: ThesaurusTagModel) {
            this.selectedFaq = faq;
            this.toggleDropdown(true);
            this.onSelected({ faq, tag });
        }

        private setFaqActionDefinitions(): any {
            this.faqActions = [
                {
                    name: 'Selecteer',
                    primary: true,
                    onExecute: (faq: FaqModel, tag: ThesaurusTagModel) => {
                        this.select(faq, tag);
                    }
                }
            ];
        }
    }
}
