namespace Umbrella {
    import MailProcessingStore = Umbrella.MailProcessing.MailProcessingStore;

    @Component('MailProcessing', {
        selector: 'mail-processing-controls',
        templateUrl:
            '/Top/MailProcessingControlsComponent/MailProcessingControls.html',
        bindings: {
            mailProcessingInfo: '<'
        }
    })
    @Inject('MailProcessingStore')
    class MailProcessingControlsComponent {
        public mailProcessingInfo: Umbrella.MailProcessing.MailProcessingState;
    }
}
