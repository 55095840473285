namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;
    import MailConversation = Umbrella.MailProcessing.MailModel;
    import DashboardMailDetails = Umbrella.MailProcessing.DashboardMailDetails;
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import ChannelType = Umbrella.Modules.ChannelType;
    import KnowledgebaseSuggestionsInput = Umbrella.CustomerService.KnowledgebaseSuggestionsInput;
    import KnowledgebaseSuggestionsBasedOn = Umbrella.CustomerService.KnowledgebaseSuggestionsBasedOn;

    export interface MailProcessingDetailsComponentState {
        registered: boolean;
        details: DashboardMailDetails;
    }

    @Component('MailProcessing', {
        selector: 'mail-processing-details',
        templateUrl: '/MailProcessing/_Overview/_Details/MailProcessingDetailsComponent/MailProcessingDetails.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$state', 'MailProcessingService', 'ToastMessageService', '$mdDialog', 'PersonResource', '$filter')
    class MailProcessingDetailsComponent {
        public state: MailProcessingDetailsComponentState;
        public faqActions: any;
        public dropdownVisible = false;
        public selectCustomerSearchQuery: string;
        public knowledgebaseSearchQuery: string;
        public knowledgebaseSuggestionsInput: KnowledgebaseSuggestionsInput;
        private selectedPerson: PersonModel;
        private selectedFaqId: System.Guid;
        private selectedTagId: System.Guid;
        private isSaving = false;

        constructor(
            private $state: ng.ui.IStateService,
            private mailService: MailProcessingService,
            private toastMessageService: ToastMessageService,
            private $mdDialog: any,
            private personResource: PersonResource,
            private $filter: ng.IFilterService
        ) {}

        public $onInit() {
            this.setFaqActionDefinitions();
        }

        public $onChanges(bindings: { state: MailProcessingDetailsComponentState }) {
            const mail = this.state && this.state.details && this.state.details.mail;

            if (!mail) return;

            if (mail.from && mail.from.displayName)
                this.selectCustomerSearchQuery = this.getSenderLastName(mail.from.displayName);

            this.knowledgebaseSuggestionsInput = this.createSuggestionsInputForKnowledgebase(mail);

            if (mail.isLinked && !this.selectedPerson) this.assignCustomer(mail.customerId, mail);
        }

        public toggleDropdown(shouldClose = false) {
            if (shouldClose) {
                this.dropdownVisible = false;
                return;
            }
            this.dropdownVisible = true;
        }

        public pickup(mail: MailModel) {
            this.mailService
                .pickup(mail)
                .catch(e =>
                    this.toastMessageService.warning(
                        'Oppakken niet mogelijk. Rond eerst andere e-mails af voordat een nieuwe e-mail opgepakt kan worden.'
                    )
                );
        }

        public setFaqActionDefinitions(): any {
            this.faqActions = [
                {
                    name: 'Select',
                    primary: true,
                    onExecute: (faq: FaqModel) => {
                        this.selectedFaqId = faq.id;
                    }
                }
            ];
        }

        public deleteMail(mailId: string) {
            const dialog = this.$mdDialog
                .confirm()
                .title('E-mail verwijderen')
                .textContent('Weet je zeker dat je dit e-mail bericht wilt verwijderen?')
                .ariaLabel('E-mail verwijderen')
                .clickOutsideToClose(true)
                .ok('Ja')
                .cancel('Nee')
                .theme('umb-green');

            this.$mdDialog.show(dialog).then((reason: string) => {
                this.mailService.deleteMail(mailId).then(
                    mail => {
                        this.mailService.deselectMail();
                        this.$state.go('dashboard.mail.overview');
                        this.toastMessageService.success('E-mail verwijderd');
                    },
                    error =>
                        this.toastMessageService.error('Fout opgetreden tijdens verwijderen van het e-mail bericht')
                );
            });
        }

        public completeMail(mailId: string) {
            this.mailService.completeMail(mailId).then(
                mail => {
                    this.mailService.deselectMail();
                    this.$state.go('dashboard.mail.overview');
                    this.toastMessageService.success('E-mail succesvol afgehandeld');
                },
                error => this.toastMessageService.error('Fout opgetreden tijdens afronden van het e-mail bericht')
            );
        }

        public refuseMail(mailId: string) {
            const dialog = this.$mdDialog
                .confirm()
                .title('E-mail weigeren')
                .htmlContent('Weet je zeker dat je dit e-mail bericht wilt weigeren?')
                .ariaLabel('E-mail weigeren')
                .clickOutsideToClose(true)
                .ok('Ja')
                .cancel('Nee')
                .theme('umb-green');

            this.$mdDialog.show(dialog).then((reason: string) => {
                this.mailService.refuseMail(mailId).then(
                    mail => {
                        this.mailService.deselectMail();
                        this.$state.go('dashboard.mail.overview');
                        this.toastMessageService.info('E-mail geweigerd');
                    },
                    error => this.toastMessageService.error('Fout opgetreden tijdens weigeren van dit e-mail bericht')
                );
            });
        }

        public canPickup(mail: MailConversation): boolean {
            if (!mail) return false;
            if (<any>mail.status == 'Processed' || mail.status == MailConversationStatus.Processed) return false;

            return !mail.pickedByOperatorId;
        }

        public canReject(mail: MailConversation): boolean {
            if (!mail) return false;

            return this.isAssignedToOperator(mail);
        }

        public canSave(mail: MailConversation): boolean {
            return !!(mail && this.selectedPerson && this.selectedFaqId) && !this.isSaving;
        }

        public canCreateContactMoment(mail: MailConversation): boolean {
            if (!mail) return false;

            return mail.pickedByOperatorId && mail.isLinked && !mail.isContactMomentCreated;
        }

        public canComplete(mail: MailConversation): boolean {
            if (!mail) return false;

            return mail.pickedByOperatorId && mail.isContactMomentCreated;
        }

        public linkConversation(personId: System.Guid, mail: MailModel) {
            this.assignCustomer(personId, mail).then(() => {

                let salutation = '';
                const naturalPerson = <Umbrella.PersonModel.Natural>this.selectedPerson;
                if (naturalPerson && naturalPerson.sex) {
                    const salutationFilter: (gender: string) => string = this.$filter('salutation');
                    salutation = salutationFilter(naturalPerson.sex.toString());
                }

                this.mailService.linkConversation(
                    mail.externalId,
                    this.selectedPerson.id,
                    this.selectedPerson.name,
                    salutation
                );
            });
        }

        public async assignCustomer(personId: System.Guid, mail: MailModel): Promise<void> {
            if (!personId) return;

            this.selectedPerson = await this.personResource.getById({
                id: personId
            }).$promise;
        }

        public assignFaq(mail: MailModel, faq: FaqModel, tag: ThesaurusTagModel): void {
            if (!faq) {
                this.clearFaq();
                return;
            }
            this.selectedFaqId = faq && faq.id ? faq.id : null;
            this.selectedTagId = tag ? tag.id : faq.mainTag.id;
            this.mailService.selectFaq(mail, faq.question);
        }

        public clearPerson(mailId: string) {
            this.selectedPerson = null;
            this.mailService.unlinkConversation(mailId);
        }

        private clearFaq() {
            this.selectedFaqId = null;
            this.selectedTagId = null;
        }

        public createContactMoment(mail: MailModel) {
            this.$mdDialog
                .show({
                    template: `<mail-processing-contact-moment-modal 
                        mail="mail"
                        person-id="personId" 
                        selected-faq-id="selectedFaqId"
                        selected-tag-id="selectedTagId">
                    </mail-processing-contact-moment-modal>`,
                    locals: {
                        mail,
                        personId: this.selectedPerson.id,
                        selectedFaqId: this.selectedFaqId,
                        selectedTagId: this.selectedTagId
                    },
                    controller: [
                        '$scope',
                        'mail',
                        'personId',
                        'selectedFaqId',
                        'selectedTagId',
                        ($scope, mail, personId, selectedFaqId, selectedTagId) => {
                            $scope.mail = mail;
                            $scope.personId = personId;
                            $scope.selectedFaqId = selectedFaqId;
                            $scope.selectedTagId = selectedTagId;
                        }],
                    targetEvent: null,
                    clickOutsideToClose: false
                });
        }

        public createTask() {
            this.$mdDialog.show({
                template: `<register-task-popup
                        person-id="${this.selectedPerson.id}"
                        selected-channel-type="${ChannelType.Email}"
                        selected-tag-id="${this.selectedTagId}"
                        selected-faq-id="${this.selectedFaqId}">
                    </register-task-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        public async save(mail: MailModel): Promise<void> {
            if (!this.selectedPerson || !this.selectedFaqId) return;

            this.createContactMoment(mail);
        }

        public reset(mail: MailModel): void {
            this.mailService.resetMailStatus(mail.externalId);
            this.clearPerson(mail.externalId);
            this.clearFaq();
        }

        private isAssignedToOperator(mail: MailConversation): boolean {
            if (!mail) return false;

            return mail.pickedByOperatorId === window.user.id;
        }

        private createSuggestionsInputForKnowledgebase(mail: MailModel): KnowledgebaseSuggestionsInput {
            const input = <KnowledgebaseSuggestionsInput>{
                query: '',
                basedOn: KnowledgebaseSuggestionsBasedOn.Email
            };

            if (!mail) return input;

            const title = mail.subject || '';
            const content = mail.body || '';
            const body = new DOMParser().parseFromString(content, 'text/html').body.outerHTML;
            const stripHtmlFilter: (text: string) => string = this.$filter('stripHtml');
            const parsedBody = stripHtmlFilter(body);
            const filteredBody = parsedBody.replace(/(&nbsp;)|(\r\n|\n|\r)/gmi, '');
            
            input.query = [title, filteredBody].join(' ');
            return input;
        }

        private getSenderLastName(displayName: string): string {
            if (!displayName || displayName.length <= 0) return '';

            const parts = displayName.split(' ');

            return parts[parts.length - 1];
        }
    }
}
