namespace Umbrella.CustomerService {
    angular
        .module('CustomerService')
        .filter('salutation', () => (gender: string): string => {
            if (!gender) return '';
            if (gender === 'Male') return 'dhr. ';
            if (gender === 'Female') return 'mevr. ';

            return '';
        });
}
