namespace Umbrella.MailProcessing {
    @Pipe('MailProcessing', 'isMailProcessingAvailable')
    class IsMailProcessingAvailableFilter {
        public transform(
            input: Umbrella.MailProcessing.MailProcessingState
        ): boolean {
            if (input) {
                if (input.enabled && input.registered) return true;
            }
            return false;
        }
    }
}
