namespace Umbrella.MailProcessing {
    @Component('MailProcessing', {
        selector: 'mail-timeline-conversation-item',
        templateUrl:
            '/MailProcessing/MailProcessingTimelineConversationItemComponent/MailProcessingTimelineConversationItem.html',
        bindings: {
            mail: '<'
        }
    })
    @Inject('MailProcessingService', '$mdDialog')
    export class MailProcessingTimelineConversationItemComponent {
        public mail: MailModel;
        public mailPreviewUrl: string;
        public isLive: boolean;

        constructor(private mailService: MailProcessingService, private $mdDialog) {}

        public $onInit() {
            if (this.mail && this.mail.id) {
                this.mail.attachments = this.mail.attachments.filter(a => !a.isInline);
                this.mailPreviewUrl = this.mailService.createPreviewUrl(<string>this.mail.id);
            }

            this.mailService.isMessageAvailableInEmailServer(this.mail.externalId).$promise.then(status => (this.isLive = status));
        }

        public createDownloadLink(id: System.Guid) {
            return Config.makeAbsoluteApiUrl('/api/v1/mailhandling/downloadattachment/' + id);
        }
    }
}
