/// <reference path="Window.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').decorator('$log', [
        '$delegate',
        ($delegate: ng.ILogService) => {
            const debugFn = $delegate.debug;
            const errorFn = $delegate.error;
            const infoFn = $delegate.info;

            function formatError(arg): string {
                const apiErrorModel = {
                    applicationName: 'ServerUI',
                    exceptionType: 'ClientException',
                    message: '',
                    exceptionMessage: '',
                    stackTrace: ''
                };

                if (arg instanceof Error && arg.stack) {
                    if (arg.message && arg.stack.indexOf(arg.message) === -1) {
                        apiErrorModel.stackTrace = arg.stack;
                        apiErrorModel.message = apiErrorModel.exceptionMessage = arg.message;
                    } else {
                        apiErrorModel.message = arg.stack;
                    }
                } else {
                    apiErrorModel.message = arg;
                }

                apiErrorModel.message +=
                    '\n\nClient information' +
                    "\nUser: '" +
                    ((window.user && window.user.name) || '(onbekend)') +
                    "' (" +
                    (window.user && window.user.id) +
                    ')' +
                    '\nLocation: ' +
                    $(location).attr('href') +
                    '\nUser agent: ' +
                    navigator.userAgent;

                return JSON.stringify(apiErrorModel);
            }

            function handle(args, url: string): void {
                const cfg = Config.configStore.getState();
                if (cfg.config && cfg.config.debug) {
                    window.console.error('Error reported by $log decorator', args);
                    return;
                }

                const error = formatError(args[0]);
                if (error.toLowerCase().includes('transition rejection')) return;
                if (error.toLowerCase().includes('possibly unhandled rejection')) return;

                const currentSession = session$.getValue();
                const accessToken = currentSession && currentSession.accessToken;

                if (accessToken) {
                    try {
                        $.ajax({
                            type: 'POST',
                            url: Config.makeAbsoluteApiUrl(url),
                            contentType: 'application/json',
                            data: error,
                            headers: { Authorization: `Bearer ${accessToken}` }
                        });
                    } catch (e) {
                        window.console && window.console.log(e);
                    }
                }
            }

            $delegate.error = (...args) => {
                handle(args, '/api/v1/error/error');
                errorFn.apply(null, args);
            };

            $delegate.debug = (...args) => {
                handle(args, '/api/v1/error/debug');
                debugFn.apply(null, args);
            };

            $delegate.info = (...args) => {
                handle(args, '/api/v1/error/info');
                infoFn.apply(null, args);
            };

            return $delegate;
        }
    ]);
}
