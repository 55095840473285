namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-processing-compose',
        templateUrl:
            '/MailProcessing/MailProcessingComposeComponent/MailProcessingCompose.html',
        bindings: {
            personId: '<',
            onSend: '&'
        }
    })
    @Inject(
        'MailProcessingService',
        'ToastMessageService',
        'ConfigResource',
        'PersonResource'
    )
    export class MailProcessingComposeComponent {
        public mailSignature: string;
        public personId: Guid;
        public onSend: (params: any) => void;
        public mail: ComposeModel;
        public isSendingMail: boolean;
        public userEmails: EmailAddressModel[];

        constructor(
            private mailService: MailProcessingService,
            private toastMessageService: ToastMessageService,
            private configResource: Umbrella.Modules.IConfigResourceClass,
            private personResource: PersonResource
        ) {}

        $onInit() {
            this.configResource
                .getAgentConfig({ name: 'exchange' })
                .$promise.then(config => {
                    if (config.mailHandling.emailSignature) {
                        this.mailSignature = config.mailHandling.emailSignature;
                    }
                });

            this.getUserEmails();
        }

        public sendEmail() {
            this.isSendingMail = true;
            let emailContent =
                this.mail.body +
                (this.mailSignature !== undefined
                    ? '<br/>' + this.mailSignature
                    : '');
            emailContent = emailContent.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.mailService
                .sendEmail(
                    this.mail.emailAddress,
                    this.mail.subject,
                    emailContent
                )
                .then(mail => {
                    this.mail.subject = '';
                    this.mail.body = '';

                    this.onSend({ conversationId: mail.conversationId });
                    this.toastMessageService.success('E-mail verzonden');
                })
                .catch(() => {
                    this.toastMessageService.error('E-mail verzenden mislukt');
                })
                .finally(() => {
                    this.isSendingMail = false;
                });
        }

        private getUserEmails() {
            this.personResource
                .getById({ id: this.personId })
                .$promise.then(person => {
                    this.userEmails = person.contactDetails.emailAddresses;
                });
        }
    }
}
