namespace Umbrella.CustomerService.CustomerCard.Bills {
    import CustomerCardContractInfoService = Umbrella.CustomerService.CustomerCard.Contracts.CustomerCardContractInfoService;

    @Service('CustomerService', 'BillJitService')
    @Inject(
        'CustomerCardStore',
        'BillHub',
        'ToastMessageService',
        'CustomerCardBillInfoService',
        'CustomerCardContractInfoService'
    )
    export class BillJitService {
        private message: any;

        constructor(
            store: CustomerCardStore,
            private billHub: IBillHubProxy,
            private toastMessageService: ToastMessageService,
            private billInfoService: CustomerCardBillInfoService,
            private contractInfoService: CustomerCardContractInfoService
        ) {
            const selectCustomerId = (s: CustomerCardState) =>
                s && s.personal && s.personal.person && s.personal.person.roles['customer'];

            const isSelected = (s: CustomerCardState, id: Guid) =>
                s &&
                s.personal &&
                s.personal.person &&
                (s.personal.person.id === id || s.personal.person.roles['customer'] === id);

            billHubOnUpdated$
                .filter(customerOrPersonId => {
                    return isSelected(store.getState(), customerOrPersonId);
                })
                .debounce(() => Rx.Observable.timer(5000))
                .subscribe(customerId => {
                    billInfoService.reload();
                    contractInfoService.reload(true);

                    this.clearToastMessage();
                });

            billHubOnStarted$
                .filter(customerId => {
                    return isSelected(store.getState(), customerId);
                })
                .subscribe(model => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Financiële gegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectCustomerId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(customerId => this.triggerJitUpdate(customerId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(_ => this.clearToastMessage());
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }

        private triggerJitUpdate(customerId: System.Guid) {
            this.clearToastMessage();

            if (customerId)
                this.billHub.server.startJitUpdate(customerId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie van financiële gegevens mislukt');
                });
        }
    }
}
