/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    angular.module('MailProcessing').config([
        '$stateProvider',
        '$urlRouterProvider',
        (
            $stateProvider: ng.ui.IStateProvider,
            $urlRouterProvider: ng.ui.IUrlRouterProvider
        ) => {
            $stateProvider.state('dashboard.mail', {
                url: '/mail',
                redirectTo: 'dashboard.mail.overview',
                data: {
                    pageTitle: 'E-Mail',
                    authorize: ps => ps.handleIncomingMails
                },
                views: {
                    overview: {
                        templateUrl:
                            '/MailProcessing/_Overview/MailProcessingOverview.html'
                    }
                }
            });
        }
    ]);
}
