namespace Umbrella.MailProcessing {
    import MailContactMoment = Umbrella.Modules.Contacts.MailContactMoment;
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-timeline-conversation',
        templateUrl:
            '/MailProcessing/MailProcessingTimelineConversationComponent/MailProcessingTimelineConversation.html',
        bindings: {
            conversationId: '<',
            activityId: '<',
            personId: '<'
        }
    })
    @Inject('MailProcessingService', '$mdDialog', 'MailProcessingStore', 'ContactActivityResource', 'PersonResource')
    export class MailProcessingTimelineConversationComponent {
        public conversationId: Guid;
        public activityId: Guid;
        public personId: Guid;
        public mailConversation: MailModel[];
        public replyTargetMail: MailModel;
        private replyDialog;
        private composeDialog;
        public canReply = false;
        public registered = false;
        private ongoingReplySub: Rx.IDisposable;
        private userEmails: EmailAddressModel[];
        constructor(
            private mailService: MailProcessingService,
            private $mdDialog,
            private mailStore: MailProcessingStore,
            private contactActivityResource: Modules.Contacts.IContactActivityResource,
            private personResource: PersonResource
        ) {}

        public $onInit() {
            this.subscribeToMailReplyAvailability();
            if (this.conversationId) {
                this.loadConversation();
            }
            this.checkUserEmails();
        }

        private checkUserEmails() {
            this.personResource.getById({ id: this.personId }).$promise.then(person => {
                this.userEmails = person.contactDetails.emailAddresses;
            });
        }

        private loadConversation = () => {
            this.mailService.getConversationById(this.conversationId).then(mails => {
                this.mailConversation = mails.sort(
                    (m1, m2) => new Date(m2.sentOn.toString()).getTime() - new Date(m1.sentOn.toString()).getTime()
                );
                if (
                    this.registered &&
                    this.mailConversation[0] &&
                    this.mailConversation[0].pickedByOperatorId === window.user.id
                ) {
                    this.canReply = true;
                }

                this.replyTargetMail = this.mailConversation.filter(x => x && x.externalId)[0];
            });
        };

        private subscribeToMailReplyAvailability() {
            this.ongoingReplySub = this.mailStore.state$
                .filter(s => s && s.mailReplyAvailability !== null && s.mailReplyAvailability !== undefined)
                .map(s => ({
                    registered: s.registered,
                    mailReplyAvailability: s.mailReplyAvailability,
                    isPickedByCurrentOperator: s.list.mails.some(m => m.pickedByOperatorId === window.user.id)
                }))
                .distinctUntilChanged()
                .subscribe(r => {
                    this.registered = r.registered ? true : false;
                    this.canReply =
                        r.registered &&
                        !r.mailReplyAvailability.some(
                            i => this.replyTargetMail && i.mailId === this.replyTargetMail.id
                        ) &&
                        r.isPickedByCurrentOperator;
                });
        }

        public finishMailReply = () => {
            this.mailService.finishMailReply(this.replyTargetMail.id);
        };

        public checkForOngoingReply() {
            this.mailService.checkForOngoingReply(this.replyTargetMail.id).done(isBusy => {
                this.canReply = this.mailStore.getState().registered && !isBusy;
            });
        }

        public openReplyDialog() {
            this.mailService.checkForOngoingReply(this.replyTargetMail.id).done(isBusy => {
                if (this.mailStore.getState() && !isBusy) {
                    const template =
                        '<mail-processing-reply-modal mail="mail" conversation-id="conversationId">' +
                        '</mail-processing-reply-modal>';

                    this.replyDialog = this.$mdDialog
                        .show({
                            template,
                            targetEvent: null,
                            locals: {
                                mail: this.replyTargetMail,
                                conversationId: this.conversationId
                            },
                            controller: [
                                '$scope',
                                'mail',
                                'conversationId',
                                ($scope, mail, conversationId) => {
                                    $scope.mail = mail;
                                    $scope.conversationId = conversationId;
                                }
                            ],
                            clickOutsideToClose: false,
                            escapeToClose: false
                        })
                        .then(result => {
                            result.callback.then(() => {
                                this.loadConversation();
                            });
                        });

                    this.mailService.startMailReply(this.replyTargetMail.id);
                } else {
                    this.canReply = false;
                }
            });
        }

        public openComposeDialog() {
            const template =
                '<mail-processing-compose-modal person-id="personId">' + '</mail-processing-compose-modal>';

            this.composeDialog = this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    locals: {
                        personId: this.personId
                    },
                    controller: [
                        '$scope',
                        'personId',
                        ($scope, personId) => {
                            $scope.personId = personId;
                        }
                    ],
                    clickOutsideToClose: false
                })
                .then((conversationId: Guid) => {
                    const mailContactMoment = new MailContactMoment(this.activityId, conversationId);
                    this.contactActivityResource.assignMailConversation(mailContactMoment).$promise.then(() => {
                        this.conversationId = conversationId;
                        this.loadConversation();
                    });
                });
        }

        public $onDestroy() {
            this.ongoingReplySub.dispose();
        }
    }
}
