/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    export interface MailHandlingOverviewStateParams {
        page: number;
    }

    angular.module('MailProcessing').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.mail.overview', {
                url: '/overzicht?page',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'E-mail overzicht',
                    authorize: ps => ps.handleIncomingMails
                },
                views: {
                    filters: {
                        template:
                            '<mail-processing-filters state="state$ | async:this"></mail-processing-filters>',
                        controller: Umbrella.createComponentRouteController<
                            Umbrella.MailProcessing.MailProcessingState,
                            MailProcessingFiltersComponentState
                        >('MailProcessingStore', s => ({
                            filters: s && s.filters
                        }))
                    },
                    list: {
                        template:
                            '<mail-processing-list state="state$ | async:this"></mail-processing-list>',
                        controller: Umbrella.createComponentRouteController<
                            Umbrella.MailProcessing.MailProcessingState,
                            MailProcessingListComponentState
                        >('MailProcessingStore', s => ({
                            list: s && s.list,
                            enabled: s && s.enabled,
                            mailsTotal: s && s.mailsTotal,
                            selectedMailId:
                                s &&
                                s.details &&
                                s.details.mail &&
                                s.details.mail.externalId
                        }))
                    },
                    details: {
                        template:
                            '<mail-processing-no-mail-selected state="state$ | async:this"></mail-processing-no-mail-selected>',
                        controller: Umbrella.createComponentRouteController<
                            Umbrella.MailProcessing.MailProcessingState,
                            MailProcessingNoMailSelectedComponentState
                        >('MailProcessingStore', s => ({
                            registered: s && s.registered
                        }))
                    }
                }
            });
        }
    ]);
}
