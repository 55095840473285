namespace Umbrella.MailProcessing {
    @Pipe('MailProcessing', 'mailAttachmentType')
    class MailAttachmentTypeFilter {
        public transform(filename: string): MailAttachmentType {
            if (!filename || filename.length < 1) return MailAttachmentType.Unknown;
            const extension = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
            if (!extension || extension.length < 1) return MailAttachmentType.Unknown;

            return this.getFileType(extension);
        }

        private getFileType(extension: string) {
            switch (extension.toLowerCase()) {
                case 'art':
                case 'blp':
                case 'bmp':
                case 'bti':
                case 'cd5':
                case 'cit':
                case 'cpt':
                case 'cr2':
                case 'csp':
                case 'cut':
                case 'dds':
                case 'dib':
                case 'djvu':
                case 'egt':
                case 'exif':
                case 'gif':
                case 'grf':
                case 'icns':
                case 'ico':
                case 'iff':
                case 'ilbm':
                case 'lbm':
                case 'jng':
                case 'jpeg':
                case 'jfif':
                case 'jpg':
                case 'jp2':
                case 'jps':
                case 'lbm':
                case 'max':
                case 'miff':
                case 'mng':
                case 'msp':
                case 'nitf':
                case 'otb':
                case 'pbm':
                case 'pc1':
                case 'pc2':
                case 'pc3':
                case 'pcf':
                case 'pcx':
                case 'pdn':
                case 'pgm':
                case 'pi1':
                case 'pi2':
                case 'pi3':
                case 'pict':
                case 'pct':
                case 'png':
                case 'pnm':
                case 'pns':
                case 'ppm':
                case 'psb':
                case 'psd':
                case 'pdd':
                case 'psp':
                case 'px':
                case 'pxm':
                case 'pxr':
                case 'qfx':
                case 'raw':
                case 'rle':
                case 'sct':
                case 'sgi':
                case 'tga':
                case 'tiff':
                case 'tif':
                case 'vtf':
                case 'xbm':
                case 'xcf':
                case 'xpm':
                case 'zif':
                    return MailAttachmentType.Image;
                case '1st':
                case '600':
                case '602':
                case 'abw':
                case 'acl':
                case 'afp':
                case 'ami':
                case 'ans':
                case 'asc':
                case 'aww':
                case 'ccf':
                case 'csv':
                case 'cwk':
                case 'dbk':
                case 'dita':
                case 'doc':
                case 'docm':
                case 'docx':
                case 'dot':
                case 'dotx':
                case 'egt':
                case 'epub':
                case 'ezw':
                case 'fdx':
                case 'ftm':
                case 'ftx':
                case 'gdoc':
                case 'html':
                case 'hwp':
                case 'hwpml':
                case 'log':
                case 'lwp':
                case 'mbp':
                case 'md':
                case 'me':
                case 'mcw':
                case 'mobi':
                case 'nb':
                case 'nbp':
                case 'neis':
                case 'odm':
                case 'odoc':
                case 'odt':
                case 'osheet':
                case 'ott':
                case 'omm':
                case 'pages':
                case 'pap':
                case 'pdax':
                case 'pdf':
                case 'quox':
                case 'rtf':
                case 'rpt':
                case 'sdw':
                case 'se':
                case 'stw':
                case 'sxw':
                case 'tex':
                case 'info':
                case 'troff':
                case 'txt':
                case 'uof':
                case 'uoml':
                case 'via':
                case 'wpd':
                case 'wps':
                case 'wpt':
                case 'wrd':
                case 'wrf':
                case 'wri':
                case 'xhtml':
                case 'xml':
                case 'xps':
                    return MailAttachmentType.Document;
                default:
                    return MailAttachmentType.Other;
            }
        }
    }
}
