/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('MailProcessing').directive('knowledgebaseDropdown', () => {
        function resizeAndPositionDropdown(element: ng.IAugmentedJQuery) {
            const triggerEl = element.find('.knowledgebase-trigger');
            const dropdownEl = element.find('.knowledgebase-dropdown');

            if (triggerEl.length < 1 || dropdownEl.length < 1) return;

            const triggerBoundingRect = triggerEl[0].getBoundingClientRect();
            const dropdownBoundingRect = dropdownEl[0].getBoundingClientRect();

            const viewportWidth = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            );
            const viewportHeight = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );

            const dropdownMaxWidth = Math.floor(
                viewportWidth - dropdownBoundingRect.left - 10
            );
            const dropdownMaxHeight = Math.floor(
                viewportHeight - dropdownBoundingRect.top - 10
            );

            const dropdownLeftPos = Math.floor(triggerBoundingRect.left);
            const dropdownTopPos = Math.floor(triggerBoundingRect.bottom + 5);

            dropdownEl.css({
                maxWidth: dropdownMaxWidth + 'px',
                maxHeight: dropdownMaxHeight + 'px',
                position: 'fixed',
                left: dropdownLeftPos,
                top: dropdownTopPos
            });
        }

        return {
            link(scope, element: ng.IAugmentedJQuery, attrs) {
                resizeAndPositionDropdown(element);

                $(window).resize(() => {
                    resizeAndPositionDropdown(element);
                });
            }
        };
    });
}
