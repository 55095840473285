namespace Umbrella {
    import CreateCustomerModel = Umbrella.Modules.Customers.CreateCustomerModel;
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;
    import debounce = Umbrella.Helpers.debounce;

    interface OnSelectedParam {
        personId: System.Guid;
    }

    @Component('Umbrella', {
        selector: 'select-customer',
        templateUrl: '/MailProcessing/SelectCustomerComponent/SelectCustomer.html',
        bindings: {
            selectedPerson: '<',
            searchQuery: '<',
            onSelected: '&',
            onCleared: '&'
        }
    })
    @Inject('$mdDialog', 'EmptyGuid', 'PersonResource')
    export class SelectCustomerComponent {
        public searchQuery: string;
        public onSelected: (params: OnSelectedParam) => void;
        public onCleared: () => void;
        public persons: PersonSearchModel[] = [];
        public dropdownVisible: boolean = false;
        public selectedPerson: PersonModel;

        constructor(private $mdDialog, private emptyGuid: System.Guid, private personResource: PersonResource) {}

        public $onInit() {
            if (!this.selectedPerson) this.loadCustomers();
        }

        public loadCustomers(): void {
            this.search(this.searchQuery);
        }

        public select(person: PersonModel) {
            this.selectedPerson = person;
            this.dropdownVisible = false;
            this.searchQuery = '';
            this.persons = [];
            this.onSelected({ personId: person.id });
        }

        public applySearch = debounce(this.search, 300, false);

        public createCustomer() {
            const person: CreateCustomerModel = {
                customerId: this.emptyGuid,
                email: this.searchQuery,
                firstName: '',
                gender: null,
                insertion: '',
                lastName: '',
                phoneNumber: '',
                externalId: null,
                agentInfoId: this.emptyGuid
            };

            this.$mdDialog
                .show({
                    template: '<relation-popup person="person"></relation-popup>',
                    targetEvent: null,
                    clickOutsideToClose: false,
                    preserveScope: true,
                    locals: { person },
                    controller: [
                        '$scope',
                        'person',
                        ($scope, person) => {
                            $scope.person = person;
                        }
                    ]
                })
                .then(customer => {
                    if (customer) this.onSelected({ personId: customer.personId });
                });
        }

        public clearCustomer() {
            this.selectedPerson = null;
            this.onCleared();
        }

        public toggleDropdown(shouldClose = false) {
            if (shouldClose) {
                this.dropdownVisible = false;
                return;
            }
            this.dropdownVisible = true;
        }

        private search(searchQuery: string): void {
            if (!searchQuery || !searchQuery.length) {
                this.persons = [];
                return;
            }

            this.personResource.search({ id: searchQuery, roles: 'customer,prospect,vendor' }).$promise.then(model => {
                this.persons = model.items;
            });
        }
    }
}
