namespace Umbrella {
    angular
        .module('Umbrella')
        .config([
            '$httpProvider',
            ($httpProvider: ng.IHttpProvider) =>
                $httpProvider.interceptors.push(
                    'UmbrellaHttpRequestInterceptor'
                )
        ])
        .factory('UmbrellaHttpRequestInterceptor', () => {
            const endsWith = (str, suffix) => {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            };

            const isLocalHtmlRequest = r => {
                return (
                    r.method === 'GET' &&
                    (endsWith(r.url, '.html') ||
                        endsWith(r.url, 'Umbrellus.json'))
                );
            };

            return {
                request: (request: ng.IRequestConfig) => {
                    if (isLocalHtmlRequest(request)) {
                        if (!request.params) {
                            request.params = {};
                        }
                    } else {
                        // tslint:disable-next-line:no-string-literal
                        request.headers['Accept'] = 'application/json';
                        request.url = Umbrella.Config.makeAbsoluteApiUrl(
                            request.url
                        );
                        request.withCredentials = true;

                        const currentSession = session$.getValue();
                        const accessToken =
                            currentSession && currentSession.accessToken;
                        if (accessToken)
                            request.headers[
                                'Authorization'
                            ] = `Bearer ${accessToken}`;
                    }
                    return request;
                }
            };
        });
}
