namespace Umbrella.MailProcessing.Overview {
    import debounce = Umbrella.Helpers.debounce;
    export interface MailProcessingFiltersComponentState {
        filters: DashboardMailFilters;
    }

    @Component('MailProcessing', {
        selector: 'mail-processing-filters',
        templateUrl:
            '/MailProcessing/_Overview/_Filters/MailProcessingFiltersComponent/MailProcessingFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('MailProcessingService')
    class MailProcessingFiltersComponent {
        public state: MailProcessingFiltersComponentState;

        public searchQuery: string;
        public selectedStatus: MailConversationStatus;

        constructor(private mailProcessingService: MailProcessingService) {}

        public $onInit(): void {
            this.searchQuery =
                (this.state &&
                    this.state.filters &&
                    this.state.filters.query) ||
                '';
            this.selectedStatus =
                (this.state &&
                    this.state.filters &&
                    this.state.filters.status) ||
                MailConversationStatus.New;
        }

        public filterByQuery = debounce(
            (query: string) => {
                this.mailProcessingService.filterMailsByQuery(query);
            },
            500,
            false
        );

        public filterByStatus(status: MailConversationStatus) {
            this.mailProcessingService.filterMailsByStatus(status);
        }
    }
}
