namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-processing-reply',
        templateUrl: '/MailProcessing/MailProcessingReplyComponent/MailProcessingReply.html',
        bindings: {
            mailExtId: '<',
            mailId: '<',
            conversationId: '<',
            onReply: '&'
        }
    })
    @Inject(
        'MailProcessingService',
        'ToastMessageService',
        'ConfigResource',
        'MailProcessingReplyDraftResource',
        'MailProcessingStore',
        'ToastMessageService'
    )
    export class MailProcessingReplyComponent {
        public mailId: Guid;
        public mailExtId: string;
        public conversationId: Guid;
        public onReply: (result: { callback: ng.IPromise<void>}) => void;
        public mailBody: string;
        public isSendingMail: boolean;
        private keypressSubject = new Rx.Subject();
        private hasReplied = false;
        public isSavingMail: boolean;

        public isRecentlySaved: boolean;

        constructor(
            private mailService: MailProcessingService,
            private toastMessageService: ToastMessageService,
            private configResource: Umbrella.Modules.IConfigResourceClass,
            private replyDraftResource: Umbrella.MailProcessing.MailProcessingReplyDraftResource,
            private mailStore: Umbrella.MailProcessing.MailProcessingStore,
            private toastService: ToastMessageService
        ) {
            this.keypressSubject.debounce(10 * 1000).subscribe((event: any) => {
                // prevents late save after reply draft has been deleted
                if (this.hasReplied) this.hasReplied = false;
                else this.saveMailDraft();
            });
        }

        public $onInit() {
            this.mailService.getReplyDraft(window.user.id, this.mailId).then(r => {
                this.mailBody = r.body;
            });
        }

        public typing(event) {
            this.keypressSubject.onNext({ key: event.key });
        }

        public async saveMailDraft() {
            if (this.isSavingMail) {
                return;
            }

            const replyDraft = {
                operatorId: window.user.id,
                replyToMailId: this.mailId,
                body: this.mailBody
            };

            this.isSavingMail = true;
            await this.replyDraftResource.save(replyDraft);
            this.isSavingMail = false;
            this.isRecentlySaved = true;
            setTimeout(() => {
                this.isRecentlySaved = false;
            }, 800);
        }

        public sendReply() {
            const registered = this.mailStore.getState().registered;
            if (registered) {
                this.isSendingMail = true;
                const response = this.mailBody.replace(/(?:\r\n|\r|\n)/g, '<br />');

                const result = this.mailService
                    .replyToEmail(this.mailExtId, response, this.conversationId)
                    .then(() => {
                        this.mailBody = '';

                        this.replyDraftResource.delete({
                            replyToMailId: this.mailId
                        });
                        this.hasReplied = true;
                        this.toastMessageService.success('E-mail verzonden');
                    })
                    .catch(() => {
                        this.toastMessageService.error('E-mail verzenden mislukt');
                    })
                    .finally(() => {
                        this.isSendingMail = false;
                    });
                    this.onReply({ callback: result });
            } else {
                this.toastService.error('Reageren is niet mogelijk, je bent niet ingelogd');
            }
        }
    }
}
