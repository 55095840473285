﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    contractHub: Umbrella.CustomerService.CustomerCard.Contracts.IContractHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    import ContractModel = Umbrella.Modules.Housing.ContractModel;

    export interface IContractHubProxy extends HubProxy {
        client: IContractHubClient;
        server: IContractHubServer;
    }

    export const contractHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const contractHubOnStarted$ = new Rx.Subject<System.Guid>();
    export const contractHubOnContractUpdated$ = new Rx.Subject<System.Guid>();

    export interface IContractHubClient {
        updated(contracts: System.Guid);
        started(customerId: System.Guid);
        contractUpdated(contractId: System.Guid);
    }

    export interface IContractHubServer {
        startJitUpdate(customerId: System.Guid);
    }

    angular
        .module('CustomerService')
        .factory('ContractHub', () => $.connection.contractHub);

    Modules.onSignalRInitialized(() => {
        $.connection.contractHub.client.updated = model => {
            contractHubOnUpdated$.onNext(model);
        };

        $.connection.contractHub.client.started = customerId => {
            contractHubOnStarted$.onNext(customerId);
        };

        $.connection.contractHub.client.contractUpdated = contractId => {
            contractHubOnContractUpdated$.onNext(contractId);
        };
    });
}
