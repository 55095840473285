﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../ComponentRouteUtils.ts" />

namespace Umbrella.CustomerService.CustomerCard {
    import CustomerCardContractInfoService = Contracts.CustomerCardContractInfoService;
    import ChannelType = Modules.ChannelType;
    import CustomerCardSidebarComponentState = Sidebar.CustomerCardSidebarComponentState;

    export interface CardQueryParams {
        channel?: string;
        query?: string;
        tag?: System.Guid;
        faq?: System.Guid;
    }

    export interface CardStateParams extends CardQueryParams {
        personId: System.Guid;
        caseId: System.Guid;
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard', {
                url: '/klantenkaart/:personId?channel&tag&query&faq',
                reloadOnSearch: false,
                redirectTo: 'customerservice.customercard.timeline',
                data: { pageTitle: 'Relatiekaart' },
                views: {
                    'right@customerservice': {
                        template:
                            '<customer-card-sidebar state-stream="state$"></customer-card-sidebar>',
                        controller: createComponentRouteController<
                            CustomerCardState,
                            CustomerCardSidebarComponentState
                        >('CustomerCardStore', s => ({
                            loading:
                                (s.personal && s.personal.loading) ||
                                (s.contractInfo && s.contractInfo.loading) ||
                                (s.accountInfo && s.accountInfo.loading) ||
                                (s.customerInfo && s.customerInfo.loading) ||
                                (s.registrationInfo && s.registrationInfo.loading),
                            person: s.personal && s.personal.person,
                            account: s.accountInfo && s.accountInfo.account,
                            customer: s.customerInfo && s.customerInfo.customer,
                            registrationInfo : s.registrationInfo && s.registrationInfo.registrations,
                            contracts:
                                s && s.contractInfo && s.contractInfo.contracts,
                            contractors:
                                s &&
                                s.contractInfo &&
                                s.contractInfo.contractors,
                            outstandingBalance:
                                s &&
                                s.contractInfo &&
                                s.contractInfo.outstandingBalance,
                            panelState: s && s.sidebar
                        }))
                    }
                },
                onEnter: [
                    '$stateParams',
                    'CustomerCardPersonInfoService',
                    'CustomerCardAccountInfoService',
                    'CustomerCardContractInfoService',
                    'ActivityRegistrationService',
                    'CustomerCardRegistrationInfoService',
                    (
                        $stateParams: CardStateParams,
                        personInfoService: CustomerCardPersonInfoService,
                        accountInfoService: CustomerCardAccountInfoService,
                        contractInfoService: CustomerCardContractInfoService,
                        activityRegistrationService: Activities.Registration.ActivityRegistrationService,
                        registrationInfoService: CustomerCardRegistrationInfoService
                    ) => {
                        personInfoService.load($stateParams.personId);
                        accountInfoService.load($stateParams.personId);
                        contractInfoService.ensureLoaded();
                        activityRegistrationService.initialize(
                            $stateParams.personId,
                            $stateParams.channel,
                            $stateParams.tag,
                            $stateParams.faq,
                            $stateParams.query
                        );
                        registrationInfoService.ensureLoaded($stateParams.personId);
                    }
                ],
                onExit: [
                    'CustomerCardPersonInfoService',
                    (personInfoService: CustomerCardPersonInfoService) => {
                        personInfoService.unload();
                    }
                ]
            });
        }
    ]);
}
