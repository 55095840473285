/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    angular.module('MailProcessing').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state(
                'dashboard.mail.overview.details.attachments',
                {
                    url: '/bijlagen',
                    data: {
                        pageTitle: 'E-mail bijlagen',
                        authorize: ps => ps.handleIncomingMails
                    },
                    views: {
                        tabcontent: {
                            template:
                                '<mail-attachments state="state$ | async:this"></mail-attachments>',
                            controller: Umbrella.createComponentRouteController<
                                Umbrella.MailProcessing.MailProcessingState,
                                MailProcessingMailAttachmentsComponentState
                            >('MailProcessingStore', s => ({
                                userAttachments:
                                    s &&
                                    s.details &&
                                    s.details.mail &&
                                    s.details.mail.attachments,
                                mailId:
                                    s &&
                                    s.details &&
                                    s.details.mail &&
                                    s.details.mail.externalId
                            }))
                        }
                    }
                }
            );
        }
    ]);
}
