﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Tasks {
    export interface ITaskResource extends angular.resource.IResource<Detailed.WithChannel>, Detailed.WithChannel {}

    export interface IFilterTasksParams extends PagedRequestParams {
        createdById?: System.Guid;
        completeById?: System.Guid;
        status?: string | TaakStatus;
        completion?: string | CompletionState;
        taskTypes?: string[];
        searchQuery?: string;
    }

    export class TaskDeadlineModel {
        deadline: Date;
    }

    export class ITaskOutcomeParams {
        taskId: System.Guid;
    }

    export class CreateFaqSuggestionParams {
        question: string;
        suggestion: string;
        fromOperatorId: Guid;
    }

    export interface ITaskResourceClass extends angular.resource.IResourceClass<ITaskResource> {
        getById(
            params: IdRequestParams,
            succes?: (data: TaskModel.Detailed) => void,
            error?: (reason: any) => void
        ): ng.resource.IResource<TaskModel.Detailed>;
        getByIds(params: IdsRequestParams): ng.resource.IResource<TaskModel[]>;
        getByCaseId(params: IdRequestParams): ng.resource.IResource<TaskModel[]>;
        getLinkedTasks(params: IdRequestParams): ng.resource.IResource<TaskModel[]>;
        getTasks(params: IFilterTasksParams): ng.resource.IResource<PagedItemsModel<TaskOverviewItemModel>>;
        update(model: TaskModel.Detailed): ITaskResource;
        queryVisibleTypes(): ng.resource.IResource<TaskTypeModel[]>;
        queryAllTypes(): ng.resource.IResource<TaskTypeModel[]>;
        queryAllTypesExceptCaseFlow(): ng.resource.IResource<TaskTypeModel[]>;
        getDefaultTaskType(): ng.resource.IResource<TaskTypeModel>;
        getHistory(
            params: PagedRequestWithIdParams,
            succes?: (data: PagedItemsModel<ActivityModel>) => void,
            error?: (reason: any) => void
        ): ng.IPromise<PagedItemsModel<ActivityModel>>;
        pickUp(params: IdRequestParams): ng.resource.IResource<TaskModel>;
        discuss(params: IdRequestParams, message: string): ng.resource.IResource<void>;
        finish(params: IdRequestParams, message: string): ng.resource.IResource<TaskModel>;
        reject(params: IdRequestParams, message: string): ng.resource.IResource<TaskModel>;
        getDefaultDeadline(
            succes?: (data: TaskDeadlineModel) => void,
            error?: (reason: any) => void
        ): ng.IPromise<TaskDeadlineModel>;
        createFaqSuggestion(params: CreateFaqSuggestionParams): ng.resource.IResource<TaskModel.Detailed>;
        linkAnonymousTask(model: LinkAnonymousTaskModel): ng.resource.IResource<TaskModel.Detailed>;
    }
    angular.module('TaskHandling').service('TaskResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/task/:action/:id/:subaction',
                {},
                {
                    getById: {
                        method: 'GET',
                        params: { id: '' },
                        isArray: false
                    },
                    getByIds: {
                        method: 'GET',
                        params: { action: 'byIds', id: '' },
                        isArray: true
                    },
                    getByCaseId: {
                        method: 'GET',
                        params: { action: 'forCase', id: '@id' },
                        isArray: true
                    },
                    getLinkedTasks: {
                        method: 'GET',
                        params: { action: 'getLinkedTasks', id: '@id' },
                        isArray: true
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            "Content-Type": "multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p",
                            "Content-Disposition": "form-data; name=file", 
                        }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    delete: {
                        method: 'PUT',
                        params: { id: '@id', subaction: 'delete' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    getTasks: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'overview', page: 0, pageSize: 10 }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            id: '@id',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    queryVisibleTypes: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'types',
                            subaction: 'visible'
                        }
                    },
                    queryAllTypes: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'types',
                            subaction: 'all'
                        }
                    },
                    queryAllTypesExceptCaseFlow: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'types',
                            subaction: 'allexceptcaseflow'
                        }
                    },
                    getDefaultTaskType: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'types',
                            subaction: 'default'
                        }
                    },
                    getHistory: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'GetHistory', page: 0, pageSize: 10 }
                    },
                    discuss: {
                        method: 'POST',
                        isArray: false,
                        params: { id: '@id', subaction: 'discuss' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    pickUp: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', action: 'pickUp' }
                    },
                    finish: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', subaction: 'finish' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    reject: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', subaction: 'reject' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    getDefaultDeadline: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'GetDefaultDeadline' }
                    },
                    createFaqSuggestion: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'createFaqSuggestion' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    linkAnonymousTask: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'linkAnonymousTask', id: '@id' },
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    }
                }
            )
    ]);
}
