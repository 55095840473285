/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import PhoneType = Modules.Telephony.PhoneType;

    @Service('CustomerService', 'CustomerCardPersonInfoService')
    @Inject('PersonResource', 'AdminPersonResource', 'CustomerCardStore', 'EmptyGuid', 'SynchronizedObjectResource')
    export class CustomerCardPersonInfoService {
        constructor(
            private personResource: PersonResource,
            private adminPersonResource: AdminPersonResource,
            private customerCardStore: CustomerCardStore,
            private emptyGuid: System.Guid,
            private synchronizedObjectResource: SynchronizedObjectResource
        ) {}

        public load(id: System.Guid): ng.IPromise<PersonModel> {
            this.emit({ type: 'CustomerCardPersonLoadingEvent', id });

            const promise = this.personResource.getById({ id }).$promise;

            promise
                .then(person => {
                    this.addEmptyPhoneNumbersEmailAddressAndPostalAddressWhenNotAvailable(person);
                    this.makeAddressTypeNumericToGuaranteeFixedSorting(person);
                    this.emit({
                        type: 'CustomerCardPersonLoadedEvent',
                        person
                    });
                })
                .catch(e =>
                    this.emit({
                        type: 'CustomerCardPersonLoadErrorEvent',
                        error: e
                    })
                );

            this.loadWritablePersonFields(id);
            this.loadSynchronizedObjects(id);

            return promise;
        }

        private loadSynchronizedObjects(personid: System.Guid) : void {
            this.synchronizedObjectResource.byEntityAndId({entitytype: 'Person', entityid: personid}).$promise
                .then(data =>
                    this.emit({
                        type: 'CustomerCardPersonSynchronizedObjectsLoadedEvent',
                        personIsDeletable: data && data.length === 0
                    })
                ).catch(err => console.log(err));
        }

        private loadWritablePersonFields(id: System.Guid): void {
            this.personResource.getWritableFields({ id }).$promise.then((response: WritablePersonDataFieldsModel) => {
                this.emit({
                    type: 'CustomerCardPersonWritableFieldsLoadedEvent',
                    fields: response
                });
            });
        }

        public unload() {
            this.emit({ type: 'CustomerCardPersonUnloadedEvent' });
        }

        public update(model: PersonModel): ng.IPromise<any> {
            this.emit({ type: 'CustomerCardPersonUpdatingEvent' });

            // HACK: Remove empty addresses to prevent FieldNotWritableErrors in the server for connectors not supporting postal addresses
            const isEmptyAddress = x => x.city === '' && x.houseNumber === 0;
            model.contactDetails.addresses = model.contactDetails.addresses.filter(x => !isEmptyAddress(x));

            // HACK: Remove empty phonenumbers to prevent FieldNotWritableErrors in the server for connectors not supporting PhonyType Unknown
            const isEmptyPhoneNr = x => x.number === '' && x.original === '';
            model.contactDetails.phoneNumbers = model.contactDetails.phoneNumbers.filter(x => !isEmptyPhoneNr(x));

            const promise = this.personResource.update(model).$promise;

            promise
                .then(person => {
                    this.addEmptyPhoneNumbersEmailAddressAndPostalAddressWhenNotAvailable(person);
                    this.makeAddressTypeNumericToGuaranteeFixedSorting(person);
                    this.emit({
                        type: 'CustomerCardPersonUpdatedEvent',
                        person
                    });
                })
                .catch(e =>
                    this.emit({
                        type: 'CustomerCardPersonUpdateErrorEvent',
                        error: e
                    })
                );

            return promise;
        }

        public updateCharacteristics(model: PersonModel): ng.IPromise<any> {
            this.emit({ type: 'CustomerCardPersonUpdatingEvent' });

            const promise = this.adminPersonResource.update(model).$promise;

            promise
                .then(person => {
                    this.addEmptyPhoneNumbersEmailAddressAndPostalAddressWhenNotAvailable(person);
                    this.makeAddressTypeNumericToGuaranteeFixedSorting(person);
                    this.emit({
                        type: 'CustomerCardPersonUpdatedEvent',
                        person
                    });
                })
                .catch(e =>
                    this.emit({
                        type: 'CustomerCardPersonUpdateErrorEvent',
                        error: e
                    })
                );

            return promise;
        }

        public getSelectedPerson(): PersonModel {
            const state = this.customerCardStore.getState();
            return state && state.personal && state.personal.person;
        }

        /**
         * Add empty fields to make editting easier and to prevent business rules in presentation components
         * NOTE: DELIBERATELY NOT ADD HOME ADDRESS WHEN IT'S NOT AVAILABLE; SOME CORPORATIONS DO NOT USE HOME ADDRESSES
         */
        public addEmptyPhoneNumbersEmailAddressAndPostalAddressWhenNotAvailable(person: PersonModel) {
            if (!person.contactDetails) return;

            if (!person.contactDetails.phoneNumbers.length)
                person.contactDetails.phoneNumbers.push({
                    type: PhoneType.Unknown,
                    number: '',
                    original: '',
                    description: 'Telefoon 1',
                    hyphenated: ''
                });

            if (person.contactDetails.phoneNumbers.length === 1)
                person.contactDetails.phoneNumbers.push({
                    type: PhoneType.Unknown,
                    number: '',
                    original: '',
                    description: 'Telefoon 2',
                    hyphenated: ''
                });

            if (!person.contactDetails.emailAddresses.length)
                person.contactDetails.emailAddresses.push({
                    email: '',
                    description: 'E-mailadres',
                    properties: EmailAddressProperties.None
                });

            if (
                !person.contactDetails.addresses ||
                !person.contactDetails.addresses.filter(
                    x => x.type === Umbrella.Modules.AddressType.Postal || x.type.toString() === 'Postal'
                ).length
            )
                person.contactDetails.addresses = [
                    ...person.contactDetails.addresses,
                    {
                        addressLine: '',
                        city: '',
                        country: '',
                        fullAddressLine: '',
                        houseNumber: 0,
                        houseNumberExtension: '',
                        street: '',
                        type: Umbrella.Modules.AddressType.Postal,
                        zipCode: '',
                        id: this.emptyGuid,
                        lines: '',
                        isEditable: true
                    }
                ];
        }

        public delete(personId: System.Guid): ng.IPromise<any> {
            return this.personResource.delete({ id: personId }).$promise;
        }

        private makeAddressTypeNumericToGuaranteeFixedSorting(person: PersonModel) {
            for (const address of person.contactDetails.addresses) {
                if (address.type.toString() === 'Home') address.type = Umbrella.Modules.AddressType.Home;
                if (address.type.toString() === 'Postal') address.type = Umbrella.Modules.AddressType.Postal;
            }
        }

        private emit(e: CustomerCardEvent) {
            this.customerCardStore.event$.onNext(e);
        }
    }
}
