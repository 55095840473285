/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    agentHub: Umbrella.Modules.IAgentHub;
}

namespace Umbrella.Modules {
    export interface IAgentHub {
        client: {
            updateFacets: (facets: { [name: string]: Agents.FacetModel }) => void;
        };
    }

    function convertKeysToCamelCase(obj) {
        if (!obj || typeof obj !== 'object') {
            return null;
        }

        if (obj instanceof Array) {
            return $.map(obj, value => convertKeysToCamelCase(value));
        }

        const newObj = {};
        $.each(obj, (key, value) => {
            const camelCasedKey = key.charAt(0).toLowerCase() + key.slice(1);
            newObj[camelCasedKey] = value;
        });

        return newObj;
    }

    onSignalRInitialized(
        () =>
            ($.connection.agentHub.client.updateFacets = facets => {
                let facet: Agents.FacetModel = null;
                for (const name in facets) {
                    if (facets.hasOwnProperty(name)) {
                        facet = convertKeysToCamelCase(facets[name]);
                        window.config.facets[$.camelCase(name)] = facet;
                    }
                }
            })
    );
}
