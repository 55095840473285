namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-processing-compose-modal',
        templateUrl:
            '/MailProcessing/MailProcessingComposeModalComponent/MailProcessingComposeModal.html',
        bindings: {
            personId: '<'
        }
    })
    @Inject('MailProcessingService', '$mdDialog')
    export class MailProcessingComposeModalComponent {
        public personId: Guid;
        constructor(
            private mailService: MailProcessingService,
            private $mdDialog
        ) {}

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public postSendCallback(conversationId: Guid): void {
            this.$mdDialog.hide(conversationId);
        }
    }
}
