namespace Umbrella.MailProcessing {
    import IContractResource = Modules.Housing.IContractResource;
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;
    import IContactActivityResource = Modules.Contacts.IContactActivityResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import NewContact = Umbrella.CustomerService.CustomerCard.Activities.Registration.NewContact;
    import RoleModel = Modules.RoleModel;
    import ChannelType = Modules.ChannelType;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;

    @Component('MailProcessing', {
        selector: 'mail-processing-contact-moment-modal',
        templateUrl: '/MailProcessing/MailProcessingContactMomentModal/MailProcessingContactMomentModal.html',
        bindings: {
            mail: '<',
            personId: '<',
            selectedFaqId: '<',
            selectedTagId: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'PersonResource',
        'ContractResource',
        'ContactActivityResource',
        'ActivityRegistrationService',
        'MailProcessingService',
        'EmptyGuid',
        'ToastMessageService'
    )
    class MailProcessingContactMomentModalComponent {
        public mail: MailModel;
        private personId: System.Guid;
        private selectedFaqId: System.Guid;
        private selectedTagId: System.Guid;
        private person: PersonModel;
        public customerId: System.Guid;
        private roleId: System.Guid;
        public selectedContract: ContractFilterModel;
        public contractors: RoleModel[] = [];
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContractor: RoleModel;
        public activity: NewContact;
        public isSaving = false;
        public allContracts: ContractFilterModel[] = [];
        public enableMailReply = false;
        public mailReply: string;

        constructor(
            private $mdDialog,
            private personResource: PersonResource,
            private contractResource: IContractResource,
            private contactActivityResource: IContactActivityResource,
            private activityRegistrationService: ActivityRegistrationService,
            private mailService: MailProcessingService,
            private emptyGuid: System.Guid,
            private toastMessageService: ToastMessageService
        ) {}

        public $onChanges = (bindings: { personId: System.Guid }) => {
            this.init();
        };

        private async init() {
            await this.loadPerson(this.personId);

            this.activity = {
                description: '',
                caseId: '',
                contractId: '',
                roleId: this.roleId,
                notifyClient: false,
                direction: Umbrella.Modules.Contacts.ContactDirection.Inbound,
                isPublic: true
            };

            if (this.customerId) this.loadContracts(this.customerId);

            this.isSaving = false;
        }

        public save() {
            if (this.isSaving) return;
            this.isSaving = true;

            this.activity.roleId = (this.selectedContractor && this.selectedContractor.id) || this.roleId;
            this.activity.contractId = this.selectedContract && this.selectedContract.id;

            const req: Modules.Contacts.RegisterContactModel = {
                caseId: this.activity.caseId,
                channelType: ChannelType.Email,
                tagId: this.selectedTagId,
                faqId: this.selectedFaqId,
                targetGroupId: null,
                followUpQuestionId: null,
                contractId: this.activity.contractId,
                roleId: this.activity.roleId,
                description: this.activity.description,
                notifyClient: !this.enableMailReply ? this.activity.notifyClient : false,
                mailId: this.mail.id,
                direction: this.activity.direction,
                mediaIds: [],
                isPublic: true
            };

            this.contactActivityResource
                .register(req)
                .$promise.then(activity => {
                    this.mailService.saveAndLinkToContactActivity(activity, this.mail).then(
                        () => {
                            if (this.enableMailReply) {
                                this.mailService
                                    .replyToEmail(this.mail.externalId, this.mailReply, this.mail.conversationId)
                                    .catch(() => {
                                        this.toastMessageService.error('Fout opgetreden tijdens beantwoorden van de e-mail');
                                    });
                            }
                        },
                        error => {
                            this.toastMessageService.error('Fout opgetreden tijdens registreren contactmoment');
                        }
                    );
                    return activity;
                })
                .then(activity => {
                    this.toastMessageService.success('Contactmoment vastgelegd');
                    this.activityRegistrationService.selectChannel(req.channelType);
                    this.$mdDialog.hide(activity);
                })
                .catch(e => {
                    if (window.clientConfig && window.clientConfig.debug) console.log(e);
                    this.toastMessageService.error('Fout opgetreden tijdens vastleggen contactmoment');
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 200);
                });
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public selectContract(contract: ContractFilterModel): void {
            if (!contract) {
                this.selectedContract = null;
                this.contractors = [];
                return;
            }

            this.selectedContract = contract;
            this.loadContractors(contract.id);
        }

        private async loadContracts(customerId: System.Guid): Promise<void> {
            const contractList = await this.contractResource.getAllFilterValuesByRoleId({
                id: customerId,
            }).$promise;
            this.displayedContracts = this.allContracts = contractList;

            const preselectedContract =
                !this.selectedContract && this.allContracts.filter(x => this.isActiveOrFutureContract(x))[0];

            if (preselectedContract)
                this.selectContract(this.allContracts.filter(x => x.id === preselectedContract.id)[0]);
        }

        private isActiveOrFutureContract(contract: ContractFilterModel): boolean {
            switch (contract.status.toString().toLowerCase()) {
                case 'active':
                case '1':
                case 'future':
                case '2':
                    return true;
                default:
                    return false;
            }
        }

        private async loadPerson(personId: System.Guid): Promise<void> {
            if (!personId) return;

            this.person = await this.personResource.getById({ id: personId }).$promise;
            this.customerId = this.person && this.person.roles['customer'];
            this.roleId =
                this.person &&
                (this.person.roles['customer'] ||
                    this.person.roles['occupant'] ||
                    this.person.roles['vendor'] ||
                    this.person.roles['prospect']);
        }

        private async loadContractors(contractId: System.Guid): Promise<void> {
            this.contractors = await this.contractResource.getContacts({
                id: contractId
            }).$promise;
            this.selectedContractor = this.contractors.filter(x => x.id === this.customerId)[0];
        }
    }
}
