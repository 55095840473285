namespace Umbrella.ChatConversationHandling {
    import IResource = ng.resource.IResource;
    import WaitingStatisticsModel = Umbrella.Chat.WaitingStatisticsModel;
    import OperatorModel = Umbrella.Chat.OperatorModel;
    import ConversationRequestModel = Umbrella.Chat.ConversationRequestModel;
    import AddMessageInput = Umbrella.Chat.AddMessageInput;
    import ChatDefaultMessageModel = Umbrella.Chat.ChatDefaultMessageModel;
    import ChatOperatorModel = Umbrella.Chat.ChatOperatorModel;

    interface CustomerConversationRequestParams extends IdRequestParams {
        customerId: System.Guid;
    }

    interface LoginModel {
        firstName: string;
        lastName: string;
        avatar: string;
    }

    export interface ConversationIdResponseModel {
        kccConversationId: { kccConversationId: System.Guid };
    }

    export interface ChatResource {
        getById(params: IdRequestParams): IResource<ChatConversation>;
        getAllOperators(): IResource<OperatorModel[]>;
        getDefaultMessages(): IResource<{messages: ChatDefaultMessageModel[]}>;
        register(params: LoginModel): IResource<void>;
        login(): IResource<void>;
        logout(): IResource<void>;
        getActiveConversations(params: { userId: System.Guid }): IResource<any[]>;
        getPendingConversations(params: { userId: System.Guid }): IResource<ConversationRequestModel[]>;
        accept(params: IdRequestParams): IResource<ConversationIdResponseModel>;
        pickup(params: IdRequestParams): IResource<void>;
        detach(params: IdRequestParams): IResource<void>;
        leave(params: IdRequestParams): IResource<void>;
        endAcw(params: IdRequestParams): IResource<void>;
        sendMessage(params: IdRequestParams, message: AddMessageInput): IResource<void>;
        linkCustomer(params: CustomerConversationRequestParams): IResource<void>;
        unlinkCustomer(params: IdRequestParams): IResource<void>;
        getWaitingStats(): IResource<WaitingStatisticsModel>;
    }

    angular.module('Chat').service('ChatResource', [
        '$resource',
        $resource => {
            const actionUrl = '/chatkcc/api/v1/:action';
            const actionIdSubactionUrl = '/chatkcc/api/v1/:action/:id/:subaction';
            const actionSubactionUrl = '/chatkcc/api/v1/:action/:subaction';
            const actionSubactionSubsubactionUrl = '/chatkcc/api/v1/:action/:subaction/:subsubaction';

            return $resource(
                '/chatkcc/api/v1/:action/:subaction/:id/:subsubaction/:subid',
                {},
                {
                    getById: {
                        url: '/chatkcc/api/v1/:action/:id',
                        method: 'GET',
                        isArray: false,
                        params: { action: 'conversations', id: '@id' }
                    },
                    getAllOperators: {
                        url: actionUrl,
                        method: 'GET',
                        isArray: true,
                        params: { action: 'chatoperators' }
                    },
                    getDefaultMessages: {
                        url: actionUrl,
                        method: 'GET',
                        isArray: false,
                        params: { action: 'defaultmessages' }
                    },
                    register: {
                        url: actionSubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'chatoperators',
                            subaction: 'register'
                        },
                        transformRequest: (data: string) => {
                            return JSON.stringify(data);
                        }
                    },
                    login: {
                        url: actionSubactionSubsubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'chatoperators',
                            subaction: 'sessions',
                            subsubaction: 'login'
                        }
                    },
                    logout: {
                        url: actionSubactionSubsubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'chatoperators',
                            subaction: 'sessions',
                            subsubaction: 'logout'
                        }
                    },
                    getPendingConversations: {
                        url: actionSubactionUrl,
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'conversations',
                            subaction: 'pending'
                        }
                    },
                    getActiveConversations: {
                        url: actionSubactionUrl,
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'conversations',
                            subaction: 'active'
                        }
                    },
                    accept: {
                        url: '/chatkcc/api/v1/conversations/pending/:id/accept',
                        method: 'POST',
                        isArray: false,
                        params: {
                            id: '@id'
                        }
                    },
                    pickup: {
                        url: actionIdSubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'conversations',
                            id: '@id',
                            subaction: 'pickup'
                        }
                    },
                    sendMessage: {
                        method: 'POST',
                        url: '/chatkcc/api/v1/conversations/:id/messages/new',
                        isArray: false,
                        params: {
                            id: '@id'
                        },
                        transformRequest: (data: string) => {
                            return JSON.stringify(data);
                        }
                    },
                    leave: {
                        url: actionIdSubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'conversations',
                            id: '@id',
                            subaction: 'leave'
                        }
                    },
                    endAcw: {
                        url: actionIdSubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'conversations',
                            id: '@id',
                            subaction: 'endacw'
                        }
                    },
                    detach: {
                        url: actionIdSubactionUrl,
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'conversations',
                            id: '@id',
                            subaction: 'detach'
                        }
                    },
                    linkCustomer: {
                        url: '/chatkcc/api/v1/conversations/:id/customerId/:customerId',
                        method: 'PUT',
                        isArray: false,
                        params: {
                            id: '@id',
                            customerId: '@customerId'
                        }
                    },
                    unlinkCustomer: {
                        url: '/chatkcc/api/v1/conversations/:id/customerId/',
                        method: 'DELETE',
                        isArray: false,
                        params: {
                            id: '@id'
                        }
                    },
                    getWaitingStats: {
                        url: actionUrl,
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'waitingstatistics'
                        }
                    }
                }
            );
        }
    ]);
}
