namespace Umbrella.CustomerService.CustomerCard.Activities {
    @Service('CustomerService', 'ActivityJitService')
    @Inject('CustomerCardStore', 'ActivityHub', 'CustomerCardActivityInfoService', 'ToastMessageService')
    export class ActivityJitService {
        private message: any;

        constructor(
            protected store: CustomerCardStore,
            private activityHub: IActivityHubProxy,
            private customerCardActivityInfoService: CustomerCardActivityInfoService,
            private toastMessageService: ToastMessageService
        ) {
            const selectPersonId = (state: CustomerCardState) =>
                state && state.personal && state.personal.person && state.personal.person.id;

            activityHubOnUpdated$
                .filter(personId => {
                    return selectPersonId(store.getState()) === personId;
                })
                .debounce(() => Rx.Observable.timer(5000))
                .subscribe(list => {
                    this.clearToastMessage();
                    this.customerCardActivityInfoService.reload();
                });

            activityHubOnStarted$
                .filter(personId => {
                    return selectPersonId(store.getState()) === personId;
                })
                .subscribe(() => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Tijdlijn gegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectPersonId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(personId => this.triggerJitUpdate(personId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(_ => this.clearToastMessage());
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }

        private triggerJitUpdate(personId: System.Guid) {
            this.clearToastMessage();

            if (personId)
                this.activityHub.server.startJitUpdate(personId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie tijdlijn gegevens mislukt');
                });
        }
    }
}
