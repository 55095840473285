/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    angular.module('MailProcessing').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.mail.overview.details.content', {
                url: '/inhoud',
                data: {
                    pageTitle: 'E-mail inhoud',
                    authorize: ps => ps.handleIncomingMails
                },
                views: {
                    tabcontent: {
                        template:
                            '<mail-content state="state$ | async:this"></mail-content>',
                        controller: Umbrella.createComponentRouteController<
                            Umbrella.MailProcessing.MailProcessingState,
                            MailProcessingMailContentComponentState
                        >('MailProcessingStore', s => ({
                            mailId:
                                s &&
                                s.details &&
                                s.details.mail &&
                                s.details.mail.externalId
                        }))
                    }
                }
            });
        }
    ]);
}
