/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    @Service('CustomerService', 'ContractJitService')
    @Inject('CustomerCardStore', 'ContractHub', 'ToastMessageService', 'CustomerCardContractInfoService')
    export class ContractJitService {
        private message: any;

        constructor(
            store: CustomerCardStore,
            private contractHub: IContractHubProxy,
            private toastMessageService: ToastMessageService,
            private contractInfoService: CustomerCardContractInfoService
        ) {
            const selectCustomerId = (s: CustomerCardState) =>
                s && s.personal && s.personal.person && s.personal.person.roles['customer'];

            const containsContractId = (s: CustomerCardState, contractId) => {

                if (s && s.contractInfo && s.contractInfo.contracts && s.contractInfo.contracts.items && s.contractInfo.contracts.items) {
                    return s.contractInfo.contracts.items.some(x => x.id === contractId);
                }

                return false;
            };

            contractHubOnUpdated$
                .filter(customerId => {
                    return selectCustomerId(store.getState()) === customerId;
                })
                .subscribe(customerId => {
                    contractInfoService.reload();
                    this.clearToastMessage();
                });

            contractHubOnContractUpdated$
                .filter(contractId => {
                    return containsContractId(store.getState(), contractId);
                })
                .subscribe(contractId => {
                    contractInfoService.reload();
                });

            contractHubOnStarted$
                .filter(customerId => {
                    return selectCustomerId(store.getState()) === customerId;
                })
                .subscribe(model => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Contractgegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectCustomerId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(customerId => this.triggerJitUpdate(customerId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(_ => this.clearToastMessage());
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }

        private triggerJitUpdate(customerId: System.Guid) {
            this.clearToastMessage();

            if (customerId)
                this.contractHub.server.startJitUpdate(customerId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie contractgegevens mislukt');
                });
        }
    }
}
