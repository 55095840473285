namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    export interface EnabledEvent {
        type: 'EnabledEvent';
    }

    export interface DisabledEvent {
        type: 'DisabledEvent';
    }

    export interface RegisteredEvent {
        type: 'RegisteredEvent';
        roleId: System.Guid;
    }

    export interface RegisterFailEvent {
        type: 'RegisterFailEvent';
    }

    export interface DeregisterEvent {
        type: 'DeregisterEvent';
        roleId: System.Guid;
    }

    export interface NewMailReceived {
        type: 'NewMailReceived';
        mail: MailModel;
        total: number;
    }

    export interface DashboardMailsLoadingEvent {
        type: 'DashboardMailsLoadingEvent';
    }

    export interface DashboardMailsLoadingFailedEvent {
        type: 'DashboardMailsLoadingFailedEvent';
        error: string;
    }

    export interface DashboardMailsLoadedEvent {
        type: 'DashboardMailsLoadedEvent';
        mailConversations: MailModel[];
        mailsTotal: number;
    }

    export interface DashboardMailsListResetEvent {
        type: 'DashboardMailsListResetEvent';
    }

    export interface DashboardMailsFiltersChangedEvent {
        type: 'DashboardMailsFiltersChangedEvent';
        query: string;
        status: number;
    }

    export interface DashboardMailsMailRemovedEvent {
        type: 'DashboardMailsMailRemovedEvent';
        mailId: string;
    }

    export interface DashboardSelectedMailLoadingEvent {
        type: 'DashboardSelectedMailLoadingEvent';
        mailId: string;
    }

    export interface DashboardSelectedMailLoadingFailedEvent {
        type: 'DashboardSelectedMailLoadingFailedEvent';
        error: string;
    }

    export interface DashboardSelectedMailLoadedEvent {
        type: 'DashboardSelectedMailLoadedEvent';
        mail: MailModel;
    }

    export interface DashboardSelectedMailDeselectedEvent {
        type: 'DashboardSelectedMailDeselectedEvent';
    }

    export interface MailPickedUpEvent {
        type: 'MailPickedUpEvent';
        operatorId: System.Guid;
        mailId: string;
        isAnonymous: boolean;
    }

    export interface MailStatusResetEvent {
        type: 'MailStatusResetEvent';
        mailId: string;
    }

    export interface MailsStatusResetEvent {
        type: 'MailsStatusResetEvent';
        mailIds: string[];
    }

    export interface PickedMailsAddedEvent {
        type: 'PickedMailsAddedEvent';
        mail: MailModel;
    }

    export interface MailContactMomentCreatedEvent {
        type: 'MailContactMomentCreatedEvent';
        mailId: string;
    }

    export interface PickedMailsRemovedEvent {
        type: 'PickedMailsRemovedEvent';
        mailId: string;
    }

    export interface MailPersistedEvent {
        type: 'MailPersistedEvent';
        externalId: string;
        mailId: System.Guid;
        conversationId: System.Guid;
    }

    export interface MailLinkedEvent {
        type: 'MailLinkedEvent';
        mailId: string;
        customerId: System.Guid;
        customerName: string;
    }

    export interface MailUnlinkedEvent {
        type: 'MailUnlinkedEvent';
        mailId: string;
    }

    export interface MailFaqSelectedEvent {
        type: 'MailFaqSelectedEvent';
        faqQuestion: string;
    }

    export interface MailBarMenuEvent {
        type: 'MailBarMenuEvent';
        mailId: string;
        isMenuOpened: boolean;
    }

    export interface OngoingMailReplyEvent {
        type: 'OngoingMailReplyEvent';
        mailId: Guid;
        operatorId: Guid;
    }

    export interface FinishedMailReplyEvent {
        type: 'FinishedMailReplyEvent';
        mailId: Guid;
        operatorId: Guid;
    }

    export interface MailReplyAvailabilityLoadedEvent {
        type: 'MailReplyAvailabilityLoadedEvent';
        mailReplyAvailability: MailReplyAvailabilityModel[];
    }

    export type MailProcessingEvent =
        | EnabledEvent
        | DisabledEvent
        | RegisteredEvent
        | RegisterFailEvent
        | DeregisterEvent
        | NewMailReceived
        | DashboardMailsLoadingEvent
        | DashboardMailsLoadingFailedEvent
        | DashboardMailsLoadedEvent
        | DashboardMailsListResetEvent
        | DashboardMailsFiltersChangedEvent
        | DashboardMailsMailRemovedEvent
        | DashboardSelectedMailLoadingEvent
        | DashboardSelectedMailLoadedEvent
        | DashboardSelectedMailLoadingFailedEvent
        | DashboardSelectedMailDeselectedEvent
        | MailPickedUpEvent
        | MailContactMomentCreatedEvent
        | MailStatusResetEvent
        | MailsStatusResetEvent
        | PickedMailsAddedEvent
        | PickedMailsRemovedEvent
        | MailPersistedEvent
        | MailLinkedEvent
        | MailUnlinkedEvent
        | MailBarMenuEvent
        | FinishedMailReplyEvent
        | OngoingMailReplyEvent
        | MailReplyAvailabilityLoadedEvent
        | MailFaqSelectedEvent;

    export interface MailProcessingState {
        enabled: boolean;
        registered: boolean;
        list: DashboardMailList;
        selectedMailId: System.Guid;
        details: DashboardMailDetails;
        filters: DashboardMailFilters;
        pickedMails: MailModel[];
        mailsTotal: number;
        mailReplyAvailability: MailReplyAvailabilityModel[];
    }

    function reduce(e: MailProcessingEvent): ObservableStore.Reducer<MailProcessingState> {
        switch (e.type) {
            case 'EnabledEvent':
                return s => ({
                    ...s,
                    enabled: true
                });

            case 'DisabledEvent':
                return s => ({
                    ...s,
                    enabled: false
                });

            case 'RegisteredEvent':
                return s => ({
                    ...s,
                    registered: true
                });

            case 'RegisterFailEvent':
                return s => ({
                    ...s,
                    registered: false
                });

            case 'DeregisterEvent':
                return s => ({
                    ...s,
                    registered: false
                });

            case 'NewMailReceived':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: [e.mail, ...s.list.mails]
                    },
                    mailsTotal: e.total
                });

            case 'DashboardMailsLoadingEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: s.list && s.list.mails ? s.list.mails : [],
                        error: null,
                        loading: true
                    }
                });

            case 'DashboardMailsLoadingFailedEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        error: e.error,
                        loading: false
                    }
                });

            case 'DashboardMailsLoadedEvent':
                return s => ({
                    ...s,
                    list: {
                        loading: false,
                        mails: s.list.mails.concat(...e.mailConversations),
                        error: null
                    },
                    mailsTotal: e.mailsTotal
                });

            case 'DashboardMailsFiltersChangedEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: [],
                        error: null,
                        loading: false
                    },
                    filters: {
                        query: e.query ? e.query : '',
                        status: e.status ? e.status : 0
                    }
                });

            case 'DashboardMailsListResetEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: [],
                        error: null,
                        loading: false
                    }
                });

            case 'DashboardMailsMailRemovedEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: s.list.mails.filter(x => x.externalId !== e.mailId)
                    },
                    mailsTotal: --s.mailsTotal
                });

            case 'DashboardSelectedMailLoadingEvent':
                return s => ({
                    ...s,
                    selectedMailId: e.mailId,
                    details: {
                        loading: true,
                        error: null,
                        mail: null
                    }
                });

            case 'DashboardSelectedMailLoadingFailedEvent':
                return s => ({
                    ...s,
                    details: {
                        error: e.error,
                        loading: false,
                        mail: null
                    }
                });

            case 'DashboardSelectedMailLoadedEvent':
                return s => ({
                    ...s,
                    details: {
                        ...s.details,
                        loading: e.mail.externalId === s.selectedMailId ? false : s.details.loading,
                        mail: {
                            ...(e.mail.externalId === s.selectedMailId ? e.mail : s.details.mail),
                            attachments:
                                e.mail.externalId === s.selectedMailId
                                    ? e.mail.attachments && e.mail.attachments.filter(x => !x.isInline)
                                    : s.details.mail && s.details.mail.attachments
                        },
                        error: null
                    }
                });

            case 'DashboardSelectedMailDeselectedEvent':
                return s => ({
                    ...s,
                    details: {
                        ...(s.details ? s.details : null),
                        loading: false,
                        error: null,
                        mail: null
                    }
                });

            case 'MailPickedUpEvent':
                return s => {
                    const mail = s.details && s.details.mail;
                    return {
                        ...s,
                        list: {
                            ...s.list,
                            mails: s.list.mails.map(conversation => {
                                if (conversation.externalId === e.mailId) {
                                    return {
                                        ...conversation,
                                        pickedByOperatorId: e.operatorId
                                    };
                                }
                                return conversation;
                            })
                        },
                        details: {
                            ...(s.details || null),
                            mail: {
                                ...(mail || null),
                                pickedByOperatorId:
                                    mail && mail.externalId === e.mailId
                                        ? e.operatorId
                                        : mail && mail.pickedByOperatorId
                            }
                        },
                        pickedMails: [
                            ...(s.pickedMails || []),
                            {
                                ...mail
                            }
                        ]
                    };
                };

            case 'MailPersistedEvent':
                return s => {
                    const mail = s.details && s.details.mail;
                    return {
                        ...s,
                        details: {
                            ...s.details,
                            mail: {
                                ...(mail || null),
                                id: mail && mail.externalId === e.externalId ? e.mailId : mail.id,
                                conversationId:
                                    mail && mail.externalId === e.externalId ? e.conversationId : mail.conversationId
                            }
                        }
                    };
                };

            case 'MailContactMomentCreatedEvent':
                return s => ({
                    ...s,
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            isContactMomentCreated:
                                s.details.mail.externalId === e.mailId ? true : s.details.mail.isContactMomentCreated
                        }
                    }
                });

            case 'MailStatusResetEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: s.list.mails.map(mail => {
                            if (mail.externalId === e.mailId) {
                                return {
                                    ...mail,
                                    pickedByOperatorId: null
                                };
                            }
                            return mail;
                        })
                    },
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            pickedByOperatorId:
                                s.details && s.details.mail && resetPickedUpOperator([e.mailId], s.details.mail),
                            isContactMomentCreated: false
                        }
                    }
                });

            case 'MailsStatusResetEvent':
                return s => ({
                    ...s,
                    list: {
                        ...s.list,
                        mails: s.list.mails.map(mail => {
                            if (e.mailIds.indexOf(mail.externalId) > -1) {
                                return {
                                    ...mail,
                                    pickedByOperatorId: null
                                };
                            }
                            return mail;
                        })
                    },
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            pickedByOperatorId:
                                s.details && s.details.mail && resetPickedUpOperator(e.mailIds, s.details.mail)
                        }
                    }
                });

            case 'PickedMailsAddedEvent':
                return s => ({
                    ...s,
                    pickedMails: [...(s.pickedMails ? s.pickedMails : []), e.mail]
                });

            case 'PickedMailsRemovedEvent':
                return s => ({
                    ...s,
                    pickedMails: s.pickedMails ? [...s.pickedMails.filter(x => x.externalId !== e.mailId)] : []
                });

            case 'MailLinkedEvent':
                return s => ({
                    ...s,
                    pickedMails:
                        s.pickedMails &&
                        s.pickedMails.map(mail => {
                            if (mail.externalId == e.mailId) {
                                return {
                                    ...mail,
                                    customerId: e.customerId,
                                    isLinked: true,
                                    anonymousCustomerName: mail.from.displayName,
                                    from: {
                                        ...mail.from,
                                        displayName: e.customerName
                                    }
                                };
                            }
                            return mail;
                        }),
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            isLinked: s.details.mail.externalId == e.mailId ? true : s.details.mail.isLinked,
                            customerId:
                                s.details.mail.externalId == e.mailId ? e.customerId : s.details.mail.customerId,
                            anonymousCustomerName:
                                s.details.mail.externalId == e.mailId
                                    ? s.details.mail.from.displayName
                                    : s.details.mail.anonymousCustomerName,
                            from: {
                                ...s.details.mail.from,
                                displayName: e.customerName
                            }
                        }
                    },
                    list: {
                        ...s.list,
                        mails: s.list.mails.map(mail => {
                            if (mail.externalId == e.mailId) {
                                return {
                                    ...mail,
                                    isLinked: true,
                                    customerId: e.customerId,
                                    anonymousCustomerName: mail.from.displayName,
                                    from: {
                                        ...mail.from,
                                        displayName: e.customerName
                                    }
                                };
                            }
                            return mail;
                        })
                    }
                });
            case 'MailUnlinkedEvent':
                return s => ({
                    ...s,
                    pickedMails: s.pickedMails.map(mail => {
                        if (mail.externalId == e.mailId) {
                            return {
                                ...mail,
                                customerId: null,
                                isLinked: false,
                                from: {
                                    ...mail.from,
                                    displayName: mail.anonymousCustomerName
                                },
                                anonymousCustomerName: ''
                            };
                        }
                        return mail;
                    }),
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            isLinked: s.details.mail.externalId == e.mailId ? false : s.details.mail.isLinked,
                            customerId: s.details.mail.externalId == e.mailId ? null : s.details.mail.customerId,
                            from: {
                                ...s.details.mail.from,
                                displayName: s.details.mail.anonymousCustomerName
                            },
                            anonymousCustomerName: ''
                        }
                    },
                    list: {
                        ...s.list,
                        mails: s.list.mails.map(mail => {
                            if (mail.externalId == e.mailId) {
                                return {
                                    ...mail,
                                    isLinked: false,
                                    customerId: null,
                                    from: {
                                        ...mail.from,
                                        displayName: mail.anonymousCustomerName
                                    },
                                    anonymousCustomerName: ''
                                };
                            }
                            return mail;
                        })
                    }
                });
            case 'MailFaqSelectedEvent': {
                return s => ({
                    ...s,
                    details: {
                        ...s.details,
                        mail: {
                            ...s.details.mail,
                            faqQuestion: e.faqQuestion
                        }
                    }
                });
            }
            case 'MailBarMenuEvent':
                return s => ({
                    ...s,
                    pickedMails: s.pickedMails.map(mail => {
                        if (mail.externalId == e.mailId) {
                            return { ...mail, isMenuOpened: e.isMenuOpened };
                        }
                        return mail;
                    })
                });

            case 'MailReplyAvailabilityLoadedEvent': {
                return s => ({
                    ...s,
                    mailReplyAvailability: e.mailReplyAvailability
                });
            }
            case 'OngoingMailReplyEvent':
                return s => ({
                    ...s,
                    mailReplyAvailability: [...s.mailReplyAvailability, { operatorId: e.operatorId, mailId: e.mailId }]
                });

            case 'FinishedMailReplyEvent':
                return s => ({
                    ...s,
                    mailReplyAvailability: s.mailReplyAvailability.filter(a => a.mailId != e.mailId)
                });
        }
    }

    const resetPickedUpOperator = (mailIds: string[], mail: MailModel) => {
        if (mail && mail.pickedByOperatorId && mailIds.indexOf(mail.externalId) > -1) {
            return null;
        }
        return mail && mail.pickedByOperatorId;
    };

    export type MailProcessingStore = ObservableStore.EventStore<MailProcessingState, MailProcessingEvent>;

    export const mailProcessingStore: MailProcessingStore = rootStore.map(
        lens<MailProcessingState>('MailProcessing'),
        reduce
    );

    angular.module('MailProcessing').value('MailProcessingStore', mailProcessingStore);
}
