/// <reference path="../../dashboard.mail.routeconfig.ts"/>

namespace Umbrella.MailProcessing.Overview {

    export interface MailProcessingNoMailSelectedComponentState {
        registered: boolean;
    }

    @Component('MailProcessing', {
        selector: 'mail-processing-no-mail-selected',
        templateUrl:
            '/MailProcessing/_Overview/MailProcessingNoMailSelectedComponent/MailProcessingNoMailSelectedComponent.html',
        bindings: {
            state: '<'
        }
    })
    class MailProcessingNoMailSelectedComponent {
        public state: MailProcessingNoMailSelectedComponentState;
    }
}
