namespace Umbrella.MailProcessing.Overview {
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    export interface MailProcessingMailContentComponentState {
        mailId: string;
    }

    @Component('Mail', {
        selector: 'mail-content',
        templateUrl:
            '/MailProcessing/_Overview/_Details/_Content/MailContentComponent/MailContent.html',
        bindings: {
            state: '<'
        }
    })
    class MailContentComponent {
        public state: MailProcessingMailContentComponentState;
    }
}
