namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-processing-reply-modal',
        templateUrl:
            '/MailProcessing/MailProcessingReplyModalComponent/MailProcessingReplyModal.html',
        bindings: {
            mail: '<',
            conversationId: '<'
        }
    })
    @Inject('MailProcessingService', '$mdDialog')
    export class MailProcessingReplyModalComponent {
        public mail: MailModel;
        public conversationId: Guid;

        constructor(
            private mailService: MailProcessingService,
            private $mdDialog
        ) {}

        public cancel(): void {
            this.mailService.finishMailReply(this.mail.id);
            this.$mdDialog.cancel();
        }

        public postReplyCallback(callback): void {
            this.mailService.finishMailReply(this.mail.id);
            this.$mdDialog.hide({ callback });
        }
    }
}
