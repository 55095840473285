/// <reference path="../../../../mailprocessing.module.d.ts"/>

namespace Umbrella.MailProcessing.Overview {
    import MailProcessingStore = Umbrella.MailProcessing.MailProcessingStore;
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;
    import MailAttachment = Umbrella.MailProcessing.MailAttachment;
    import MediaModel = Umbrella.Media.MediaModel;

    export interface MailProcessingMailAttachmentsComponentState {
        userAttachments: MailAttachment[];
        mailId: string;
    }

    @Component('Mail', {
        selector: 'mail-attachments',
        templateUrl:
            '/MailProcessing/_Overview/_Details/_Attachments/MailAttachmentsComponent/MailAttachments.html',
        bindings: {
            state: '<'
        }
    })
    @Inject()
    class MailAttachmentsComponent {
        public state: MailProcessingMailAttachmentsComponentState;

        getAbsoluteApiUrl(attachmentId) {
            return Config.makeAbsoluteApiUrl(
                `api/v1/mailHandling/downloadLiveAttachment?mailId=${encodeURIComponent(
                    this.state.mailId
                )}&attachmentId=${encodeURIComponent(attachmentId)}`
            );
        }
    }
}
