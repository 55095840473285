﻿/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import MediaModel = Umbrella.Media.MediaModel;
    import MediaResourceProvider = Umbrella.Modules.MediaResourceProvider;
    import ITaskResourceClass = Modules.Tasks.ITaskResourceClass;
    import TaskTypeModel = Modules.Tasks.TaskTypeModel;
    import SummarizedColleagueModel = Umbrella.Modules.Colleagues.SummarizedColleagueModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import IFunctionGroupResourceClass = Modules.Colleagues.IFunctionGroupResourceClass;
    import ColleagueModel = Modules.Colleagues.ColleagueModel;

    export interface EditTaskComponentState {
        task: TaskModel.Detailed;
    }

    @Component('TaskHandling', {
        selector: 'edit-task',
        templateUrl:
            '/TaskHandling/_Overview/_Edit/EditTaskComponent/EditTask.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        '$state',
        'TaskOverviewService',
        'TaskResource',
        'ToastMessageService',
        'ColleagueResource',
        'FunctionGroupResource',
        'Upload',
        'MediaResourceProvider'
    )
    class EditTaskComponent {
        public state: TaskInformationComponentState;
        public taskTypes: TaskTypeModel[];
        public media: MediaModel[];
        public updating: boolean;
        public now = new Date();
        public selectedDate: Date;
        public assignedToList: SummarizedColleagueModel[] = [];

        constructor(
            private $state: ng.ui.IStateService,
            private taskOverviewService: TaskOverviewService,
            private taskResource: ITaskResourceClass,
            private toastMessageService: ToastMessageService,
            private colleagueResource: IColleagueResourceClass,
            private functiongroupResource: IFunctionGroupResourceClass,
            private uploadService: any,
            private mediaResourceProvider: MediaResourceProvider
        ) {}

        public $onInit() {
            this.loadTaskTypes();
            this.loadAssignedToList();
            this.loadMedia();
            if (this.state && this.state.task) {
                this.state.task.ends = new Date(this.state.task.ends.toLocaleString());
                this.selectedDate = this.state.task.ends;
            }
            this.addPasteEventListener();

        }

        public updateDeadlineDate(newDate: Date): void {
            // NOTE: implemented this method (instead of direct databinding) to prevent that time is changed when historic dates are modified (check discussion in #26249).
            this.state.task.ends.setFullYear(newDate.getFullYear());
            this.state.task.ends.setMonth(newDate.getMonth());
            this.state.task.ends.setDate(newDate.getDate());
        }

        public update(task: TaskModel.Detailed): void {
            this.updating = true;
            this.taskOverviewService
                .update(task)
                .then(() => {
                    this.toastMessageService.success('Taak bijgewerkt');
                    this.$state.go(
                        'dashboard.tasks.overview.details.information',
                        { id: task.id }
                    );
                })
                .catch(response =>
                    this.toastMessageService.error(
                        'Taak kon niet bijgewerkt worden'
                    )
                )
                .finally(() => (this.updating = false));
        }

        public deleteMediaItem(mediaItem: MediaModel): void {
            if (!this.state.task || !this.state.task.mediaIds) return;

            this.state.task.mediaIds.splice(this.state.task.mediaIds.indexOf(mediaItem.id), 1);
        }

        public upload = (file: any) => {
            const url = Config.makeAbsoluteApiUrl(
                '/api/v1/media?mediaCategory=TaskMedia'
            );

            if (file) {
                file.upload = this.uploadService
                    .upload({
                        url,
                        data: { file }
                    })
                    .then(result => {
                        if (!this.state.task.mediaIds)
                            this.state.task.mediaIds = [];

                        this.state.task.mediaIds.push(result.data.id);

                        if (!this.media)
                            this.media = [];

                        this.media.push(result.data);
                    });
            }
        };

        public canEditTaskType(): boolean {
            return (
                this.taskTypes &&
                this.taskTypes.length &&
                this.state.task &&
                this.state.task.taskType &&
                this.taskTypes.filter(
                    t => t.name === this.state.task.taskType.name
                ).length > 0
            );
        }

        public async searchAssignedTo(query = ''): Promise<void> {
            const pagedList = await this.colleagueResource.getColleaguesAndFunctiongroups(
                { page: 0, pageSize: 200, id: query, userId: null }
            ).$promise;
            this.assignedToList = pagedList.items;
        }

        private loadMedia() {
            if(!(this.state && this.state.task)) return;

            this.mediaResourceProvider.getByIdsV2({ ids : this.state.task.mediaIds}).$promise.then(data => {
                this.media = data.map(item => item);
            });
        }

        private loadTaskTypes() {
            this.taskResource.queryVisibleTypes().$promise.then(data => {
                this.taskTypes = data;
            });
        }

        private async loadAssignedToList(): Promise<void> {
            await this.searchAssignedTo('');
            await this.preSelectAssignedTo();
        }

        private preSelectAssignedTo() {
            if (this.state.task && this.state.task.completeBy) {
                const completeById = this.state.task.completeBy.id;
                const type = this.state.task.completeBy.type.toString();
                const isLoaded = completeById =>
                    this.assignedToList.filter(x => x.id === completeById)
                        .length;

                if (!isLoaded(completeById)) {
                    this.loadCompleteBy(type, completeById);
                }
            }
        }

        private async loadCompleteBy(type: string, completeById: Guid): Promise<void> {
            if (type === 'Colleagues') {
                const colleague = await this.colleagueResource.getById({
                    id: completeById
                }).$promise;
                if (colleague)
                    this.assignedToList.push(
                        this.createListItem(colleague)
                    );
            } else if (type === 'FunctionGroups') {
                const functiongroup = await this.functiongroupResource.getById(
                    { id: completeById }
                ).$promise;
                if (functiongroup)
                    this.assignedToList.push(<any>functiongroup);
            }
        }

        private createListItem(colleague: ColleagueModel.Detailed): any {
            return {
                type: 'Colleagues',
                id: colleague.id,
                name: colleague.name
            };
        }

        private addPasteEventListener(): void {
            window.addEventListener('paste', (event: Event) => {
                const clipboardEvent: ClipboardEvent = <ClipboardEvent> event;

                if (clipboardEvent.clipboardData.items) {
                    const item = clipboardEvent.clipboardData.items[0];
                    if (item)
                        this.upload(item.getAsFile());
                }
            });
        }
    }
}
