/// <reference path="../../CustomerService/_CustomerCard/CustomerCardStore.ts"/>

namespace Umbrella {
    import Guid = System.Guid;
    import PhoneService = Umbrella.Modules.Telephony.PhoneService;
    import PhoneType = Umbrella.Modules.Telephony.PhoneType;
    import PhoneCallModel = Umbrella.TelephonyControl.PhoneCallModel;
    import SessionColleagueModel = Modules.Colleagues.SessionColleagueModel;
    import RoleModelDictionary = Umbrella.Modules.Telephony.RoleModelDictionary;
    import RoleModel = Umbrella.Modules.RoleModel;
    import CustomerCardPersonInfoService = Umbrella.CustomerService.CustomerCard.CustomerCardPersonInfoService;

    export enum ConversationState {
        Idle = 0,
        Calling = 1,
        OnHold = 2,
        Acw = 3
    }

    @Component('Umbrella', {
        selector: 'phone-controls',
        templateUrl: '/Top/PhoneControlsComponent/PhoneControls.html',
        bindings: {
            telephonyInfo: '<'
        }
    })
    @Inject('$state', '$interval', '$filter', 'PhoneService', 'CustomerCardPersonInfoService')
    class PhoneControlsComponent {
        public telephonyInfo: Telephony.State;
        public activeCall: Umbrella.TelephonyControl.PhoneCallModel;
        public user: SessionColleagueModel;

        public conversationState: string;
        public person: PersonModel;

        private phoneCallTimer: ng.IPromise<void> = null;
        private rolesCache: RoleModelDictionary = {};
        private start = Date.now();
        private onHoldCallsFilter: (input: PhoneCallModel[]) => PhoneCallModel[];
        private ongoingCallsFilter: (input: PhoneCallModel[]) => PhoneCallModel[];
        private activeCallFilter: (input: PhoneCallModel[]) => PhoneCallModel;
        private personObserver: Rx.IDisposable;
        private writeableFieldObserver: Rx.IDisposable;
        private inTelephonyQueue: boolean;
        private releasing: boolean;
        private writeableFields: WritablePersonDataFieldsModel;

        constructor(
            private $state: ng.ui.IStateService,
            private $interval: ng.IIntervalService,
            private $filter: ng.IFilterService,
            private phoneService: PhoneService,
            private personInfoService: CustomerCardPersonInfoService
        ) {
            this.user = window.user;
            this.schedulePhoneCallTimer();
            this.onHoldCallsFilter = <any>$filter('onHoldCalls');
            this.ongoingCallsFilter = <any>$filter('ongoingCalls');
            this.activeCallFilter = <any>$filter('activeCall');
        }

        public $onInit() {
            this.personObserver = CustomerService.CustomerCard.customerCardStore.state$
                .map(s => s && s.personal && s.personal.person && s.personal.person)
                .filter(person => person !== null)
                .distinctUntilChanged()
                .subscribe(person => {
                    this.person = person;
                });

            this.writeableFieldObserver = CustomerService.CustomerCard.customerCardStore.state$
                .map(s => s && s.personal && s.personal.fields)
                .filter(fields => fields !== null)
                .distinctUntilChanged()
                .subscribe(fields => {
                    this.writeableFields = fields;
                });
        }

        public $onDestroy() {
            if (this.phoneCallTimer) {
                this.$interval.cancel(this.phoneCallTimer);
            }

            if (this.personObserver) {
                this.personObserver.dispose();
                this.personObserver = null;
            }

            if (this.writeableFieldObserver) {
                this.writeableFieldObserver.dispose();
                this.writeableFieldObserver = null;
            }
        }

        public $onChanges(bindings: { telephonyInfo: IBindingChange<Telephony.State> }) {
            this.start = Date.now();

            this.rolesCache = bindings.telephonyInfo.currentValue.rolesCache;

            this.conversationState = ConversationState[ConversationState.Idle];
            if (
                !bindings.telephonyInfo ||
                !bindings.telephonyInfo.currentValue ||
                !bindings.telephonyInfo.currentValue.phone
            )
                return;

            const phone = bindings.telephonyInfo.currentValue.phone;
            this.activeCall = this.activeCallFilter(phone.calls);

            if (phone.status && (phone.status.toString() === 'ACW' || phone.status === 5)) {
                this.conversationState = ConversationState[ConversationState.Acw];
            } else {
                const ongoingCalls = phone.calls && this.ongoingCallsFilter(phone.calls);
                if (ongoingCalls && ongoingCalls.length > 0) {
                    this.conversationState = ConversationState[ConversationState.Calling];

                    if (phone.calls && this.onHoldCallsFilter(phone.calls).length === phone.calls.length) {
                        this.conversationState = ConversationState[ConversationState.OnHold];
                    }
                }
            }

            this.inTelephonyQueue = phone.queueStatus.toString() === 'Available' || phone.queueStatus === 2;
        }

        public onCustomerCard(): boolean {
            return (
                this.$state &&
                this.$state.current &&
                this.$state.current.name.indexOf('customerservice.customercard') !== -1
            );
        }

        public leaveAcw() {
            this.phoneService.endAfterCallWork();
        }

        public leaveAcwAndQueue() {
            this.phoneService.endAfterCallWorkAndLeaveQueue();
        }

        private incrementCallTime() {
            const difference = (Date.now() - this.start) / 1000;

            if (this.telephonyInfo && this.telephonyInfo.phone) {
                if (this.telephonyInfo.phone.calls) {
                    this.telephonyInfo.phone.calls.forEach(call => {
                        if (call.status.toString() === 'Ringing' || call.status === 3) call.waitingTime += difference;

                        if (
                            call.status.toString() === 'Established' ||
                            call.status === 2 ||
                            call.status.toString() === 'OnHold' ||
                            call.status === 6
                        )
                            call.duration += difference;
                    });
                }

                if (
                    (this.telephonyInfo.phone.status && this.telephonyInfo.phone.status.toString() === 'ACW') ||
                    this.telephonyInfo.phone.status === 5
                )
                    this.telephonyInfo.phone.afterCallWorkTime += difference;
            }
            this.start = Date.now();
        }

        private schedulePhoneCallTimer() {
            this.phoneCallTimer = this.$interval(() => {
                this.incrementCallTime();
            }, 1000);
        }

        public holdcall(call: PhoneCallModel) {
            if (!call) return;

            this.phoneService.holdcall(call);
        }

        public releasecall(call: PhoneCallModel) {
            if (!call || this.releasing) return;

            this.releasing = true;

            this.phoneService.releasecall(call);

            setTimeout(() => {
                this.releasing = false;
            }, 500);
        }

        public forwardcall(call: PhoneCallModel) {
            if (!call) return;

            this.phoneService.forwardcall(call);
        }

        public pickup(call: PhoneCallModel) {
            if (!call) return;

            this.phoneService.pickup(call);
        }

        public link(call: PhoneCallModel, roleId: System.Guid) {
            if (!call || !roleId) return;

            this.phoneService.setRoleForCall(call, roleId);
        }

        public unlink(call: PhoneCallModel) {
            if (!call || !call.subjectRoleId) return;

            this.phoneService.unsetRoleForCall(call);
        }

        public selectCall(call: PhoneCallModel): void {
            if (!call) return;

            this.activeCall = call;
        }

        public getCallSubjectRole(call: PhoneCallModel): RoleModel {
            return call && this.getRoleModel(call.subjectRoleId);
        }

        public getRoleModel(roleId: Guid): RoleModel {
            return roleId && this.rolesCache && this.rolesCache[roleId.toString()];
        }

        public addOrUpdatePhoneNumberToCustomer(call: PhoneCallModel, person: PersonModel, roleId: Guid) {
            if (!person || !call || !roleId) return;

            if (this.isNumberAlreadyLinked(call, person)) return;

            let foundEmptySlot = false;
            const personClone = <PersonModel>JSON.parse(JSON.stringify(person));
            const phoneNumbers = personClone && personClone.contactDetails && personClone.contactDetails.phoneNumbers;

            for (let i = 0; i < phoneNumbers.length; i++) {
                if (phoneNumbers[i].number.length <= 0) {
                    phoneNumbers[i] = overwritePhone(
                        phoneNumbers[i],
                        call.number,
                        i,
                        PhoneType.Unknown
                    );
                    foundEmptySlot = true;
                    break;
                }
            }

            if (!foundEmptySlot) {
                const phoneNumber = phoneNumbers[phoneNumbers.length - 1];
                phoneNumbers[phoneNumbers.length - 1] = overwritePhone(
                    phoneNumber,
                    call.number,
                    phoneNumbers.length,
                    PhoneType.Unknown
                );
            }

            this.personInfoService.update(personClone).then(() => this.link(call, roleId));

            function overwritePhone(
                currentPhoneModel: Modules.Telephony.ContactPhoneNumberModel,
                newNumber: string,
                position: number,
                type: Umbrella.Modules.Telephony.PhoneType
            ) {
                return {
                    ...currentPhoneModel,
                    number: newNumber,
                    original: newNumber,
                    description: `Telefoon ${position}`,
                    type: type
                };
            }
        }

        public isAbleToLinkNumber(call: PhoneCallModel, person: PersonModel): boolean {
            return this.isPhoneNumberFieldUpdateable() && !this.isNumberAlreadyLinked(call, person) && this.isAllowedToAddUnknownPhoneNumber();
        }

        private isPhoneNumberFieldUpdateable(): boolean {
            const isPhoneNumberUpdateable = this.writeableFields &&
                this.writeableFields.fields &&
                !!this.writeableFields.fields.find((x: any) => x === "PhoneNumber");
            const nrOfPhoneNumbers = this.person.contactDetails.phoneNumbers.filter(x => x.number).length;

            return isPhoneNumberUpdateable && nrOfPhoneNumbers < 2;
        }

        private isAllowedToAddUnknownPhoneNumber(): boolean {
            const hasFoundUnknownPhoneNumber = !!this.person.contactDetails.phoneNumbers.find(x => x.type === PhoneType.Unknown);
            const isPhoneTypeUpdateable = this.writeableFields &&
                this.writeableFields.fields &&
                !!this.writeableFields.fields.find((x: any) => x === "PhoneType");

            return !!window.user.permissions.updateContactPhoneNumbers && isPhoneTypeUpdateable && this.writeableFields.capabilities.unknownPhoneTypeAllowed && (this.writeableFields.capabilities.multipleEqualPhoneTypesAllowed || !hasFoundUnknownPhoneNumber);
        }

        private isNumberAlreadyLinked(call: PhoneCallModel, person: PersonModel): boolean {
            if (!person || !call || !person.contactDetails || !person.contactDetails.phoneNumbers) return false;

            const phonenrFilter: (phonenr: string) => string = <any>this.$filter('phonenr');
            return !!person.contactDetails.phoneNumbers.find(x => phonenrFilter(x.number) === phonenrFilter(call.number));
        }
    }
}
