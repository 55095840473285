/// <reference path="MailHandlingHub.ts" />

namespace Umbrella.MailProcessing {
    import Guid = System.Guid;
    import IPromise = ng.IPromise;
    import PersonResource = Umbrella.PersonResource;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import MailContactMoment = Umbrella.Modules.Contacts.MailContactMoment;
    import ChannelType = Umbrella.Modules.ChannelType;
    import ContactActivityModel = Modules.Contacts.ContactActivityModel;

    export enum MailAttachmentType {
        Unknown = 0,
        Image,
        Document,
        Other
    }

    export enum MailConversationStatus {
        New,
        Processed,
        Refused,
        Deleted
    }

    export class DashboardMailList {
        loading: boolean;
        mails: MailModel[] = [];
        error: string;
    }

    export class DashboardMailDetails {
        loading: boolean;
        mail: MailModel;
        error: string;
    }

    export class DashboardMailFilters {
        query: string;
        status: MailConversationStatus;
    }

    export class ComposeModel {
        emailAddress: string;
        subject: string;
        body: string;
    }

    export class ReplyModel {
        mailId: string;
        body: string;
        conversationId: Guid;
    }

    export class MailReplyAvailabilityModel {
        mailId: Guid;
        operatorId: Guid;
    }

    export interface MailProcessingService {
        registerOperator(): IPromise<void>;
        deregisterOperator(): IPromise<void>;
        getDashboardMails(pageSize: number);
        getMailDetails(id: string): IPromise<any>;
        selectMail(id: string): IPromise<any>;
        deselectMail(): void;
        pickup(mail: MailModel): IPromise<any>;
        deleteMail(mailId: string): IPromise<any>;
        completeMail(mailId: string): IPromise<any>;
        refuseMail(mailId: string): IPromise<any>;
        resetMailStatus(mailId: string): IPromise<any>;
        createPreviewUrl(mailId: string): string;
        sendEmail(recipientEmail: string, subject: string, body: string): IPromise<any>;
        replyToEmail(mailId: string, body: string, conversationId: Guid): IPromise<any>;
        finishMailReply(mailId: Guid);
        startMailReply(mailId: Guid);
        checkForOngoingReply(mailId: Guid): MailProcessingServerResponse<boolean>;
    }

    export class MailProcessingService {
        static $inject = [
            'MailProcessingStore',
            'MailHandlingHub',
            'MailProcessingResource',
            'PersonResource',
            '$q',
            'ActivityRegistrationService',
            'ContactActivityResource'
        ];

        private emit: (e: MailProcessingEvent) => void;

        constructor(
            private mailStore: MailProcessingStore,
            private mailHub: MailHandlingHubProxy,
            private mailResource: MailProcessingResource,
            private personResource: PersonResource,
            private $q: ng.IQService,
            private activityRegistrationService: ActivityRegistrationService,
            private contactActivityResource: Modules.Contacts.IContactActivityResource
        ) {
            this.emit = (e: MailProcessingEvent) => mailStore.event$.onNext(e);

            const isMailAgentActive =
                window &&
                window.config &&
                window.config.agents &&
                window.config.agents.find(x => x.name === 'ExchangeMailHandling' && x.available && x.active);
            if (!isMailAgentActive) return;

            if (!window.user || !window.user.permissions || !window.user.permissions.handleIncomingMails) return;

            Modules.onSignalRInitialized(() => {
                this.subscribeToMailEvents();
            });
            Modules.onSignalRConnected(() => {
                this.mailHub.server.getMailsBeingRepliedTo().done(res => {
                    this.emit({
                        type: 'MailReplyAvailabilityLoadedEvent',
                        mailReplyAvailability: res.data
                    });
                });
                this.loadMailHandlingConfig();
            });
        }

        private loadMailHandlingConfig() {
            if (window.user.permissions.handleIncomingMails) {
                this.emit({ type: 'EnabledEvent' });
                this.getDashboardMails(0);
            }
        }

        private subscribeToMailEvents(): void {
            if (!this.mailHub) return;

            this.mailHub.on('MailPickedUp', (operatorId, mailId) => {
                this.emit({
                    type: 'MailPickedUpEvent',
                    operatorId,
                    mailId,
                    isAnonymous: false
                });
            });

            this.mailHub.on('MailHandled', mailId => {
                this.refreshDashboardMailList(mailId);
            });

            this.mailHub.on('MailsStatusReset', (mailIds: string[]) => {
                this.emit({ type: 'MailsStatusResetEvent', mailIds });
            });

            this.mailHub.on('MailReceived', mail => {
                const state = this.mailStore.getState();
                const query = state.filters && state.filters.query;
                const status =
                    state.filters && state.filters.status ? state.filters.status : MailConversationStatus.New;

                if (status !== MailConversationStatus.New) return;
                if (!query || query.length) {
                    this.emit({
                        type: 'NewMailReceived',
                        mail,
                        total: ++state.mailsTotal
                    });
                    return;
                }

                // This is required to check against Exchange SearchFilter
                this.mailResource.search({ page: 0, pageSize: 1, query, status }).$promise.then(mails => {
                    if (mails.items.length < 1) {
                        return;
                    }

                    const lastMail = mails.items[0];
                    const newTotal = state.mailsTotal ? ++state.mailsTotal : mails.total;

                    if (state.list.mails.filter(x => x.externalId === lastMail.externalId).length === 0) {
                        this.emit({
                            type: 'NewMailReceived',
                            mail: lastMail,
                            total: newTotal
                        });
                    }
                });
            });

            this.mailHub.on('MailStatusReset', mailId => {
                this.emit({ type: 'MailStatusResetEvent', mailId });
            });

            this.mailHub.on('OperatorReplyingToMail', (operatorId, mailId) => {
                this.emit({
                    type: 'OngoingMailReplyEvent',
                    operatorId,
                    mailId
                });
            });

            this.mailHub.on('OperatorFinishedReplyingToMail', (operatorId, mailId) => {
                this.emit({
                    type: 'FinishedMailReplyEvent',
                    operatorId,
                    mailId
                });
            });
        }

        public registerOperator(): IPromise<void> {
            return this.mailHub.server.register(window.user.id).then(() => {
                this.emit({ type: 'RegisteredEvent', roleId: window.user.id });
                this.activityRegistrationService.loadRootTags();
            });
        }

        public deregisterOperator(): IPromise<void> {
            return this.mailHub.server.deregister(window.user.id).then(() => {
                this.emit({ type: 'DeregisterEvent', roleId: window.user.id });
            });
        }

        public async getDashboardMails(pageSize = 8) {
            if (!pageSize || pageSize < 1) {
                pageSize = 1;
            }

            const state = this.mailStore.getState();
            const query = (state && state.filters && state.filters.query) || '';
            const status = (state && state.filters && state.filters.status) || MailConversationStatus.New;
            return await this.searchMails(query, status, pageSize);
        }

        public filterMailsByQuery(query: string) {
            const state = this.mailStore.getState();
            const status = (state.filters && state.filters.status) || MailConversationStatus.New;

            this.emit({
                type: 'DashboardMailsFiltersChangedEvent',
                query,
                status
            });
            this.getDashboardMails();
        }

        public filterMailsByStatus(status: MailConversationStatus) {
            const state = this.mailStore.getState();
            const query = (state.filters && state.filters.query) || '';

            this.emit({
                type: 'DashboardMailsFiltersChangedEvent',
                query,
                status
            });
            this.getDashboardMails();
        }

        private async searchMails(query: string, status: number, pageSize: number) {
            this.emit({ type: 'DashboardMailsLoadingEvent' });

            const state = this.mailStore.getState();
            const loadedMailsCount = state.list && state.list.mails ? state.list.mails.length : 0;
            const page = Math.floor(loadedMailsCount / pageSize);

            await this.mailResource.search({ page, pageSize, query, status }).$promise.then(
                mails => {
                    this.emit({
                        type: 'DashboardMailsLoadedEvent',
                        mailConversations: this.filterAlreadyAddedToState(mails.items),
                        mailsTotal: mails.total
                    });
                },
                e => {
                    this.emit({
                        type: 'DashboardMailsLoadingFailedEvent',
                        error: e
                    });
                }
            );
        }

        private filterAlreadyAddedToState(mails: MailModel[]): MailModel[] {
            const state = this.mailStore.getState();
            if (!state || !state.list || !state.list.mails || state.list.mails.length <= 0) {
                return mails;
            }

            return mails.filter(x => state.list.mails.every(t => t.externalId !== x.externalId));
        }

        public selectMail(mailId: string): IPromise<any> {
            const state = this.mailStore.getState();
            if (state && state.details && state.details.mail && state.details.mail.externalId === mailId) {
                return this.$q.reject();
            }

            this.emit({ type: 'DashboardSelectedMailLoadingEvent', mailId });
            return this.mailResource.getById({ id: encodeURIComponent(mailId) }).$promise.then((mail: MailModel) => {
                this.emit({
                    type: 'DashboardSelectedMailLoadedEvent',
                    mail
                });
            });
        }

        public deselectMail(): void {
            this.emit({ type: 'DashboardSelectedMailDeselectedEvent' });
        }

        public pickup(mail: MailModel): IPromise<any> {
            return this.mailHub.server
                .pickupMail(window.user.id, mail.externalId, mail.isAnonymous, mail.from.displayName)
                .then(() => {
                    this.emit({
                        type: 'MailPickedUpEvent',
                        operatorId: window.user.id,
                        mailId: mail.externalId,
                        isAnonymous: mail.isAnonymous
                    });
                });
        }

        public hasActiveMail(customerId: System.Guid): boolean {
            const state = this.mailStore.getState();
            if (!state || !state.pickedMails || !state.pickedMails.length) return false;

            return state.pickedMails.filter(x => x.customerId === customerId).length > 0;
        }

        public deleteMail(mailId: string): IPromise<any> {
            return this.mailHub.server.deleteMail(window.user.id, mailId).then(mail => {
                this.refreshDashboardMailList(mailId);
                this.dropFromPickedMails(mailId);
            });
        }

        public completeMail(mailId: string): IPromise<any> {
            return this.mailHub.server.completeMail(window.user.id, mailId).then(mail => {
                this.refreshDashboardMailList(mailId);
                this.dropFromPickedMails(mailId);
            });
        }

        public refuseMail(mailId: string): IPromise<any> {
            return this.mailHub.server.refuseMail(window.user.id, mailId).then(mail => {
                this.refreshDashboardMailList(mailId);
                this.dropFromPickedMails(mailId);
            });
        }

        public async saveAndLinkToContactActivity(activity: ContactActivityModel, mail: MailModel): Promise<void> {
            const savedMail = await this.mailResource.save(mail).$promise;
            this.emit({
                type: 'MailPersistedEvent',
                externalId: mail.externalId,
                mailId: savedMail.id,
                conversationId: savedMail.conversationId
            });
            const mailContactMoment = new MailContactMoment(activity.id, savedMail.conversationId);
            this.contactActivityResource.assignMailConversation(mailContactMoment).$promise.then(contactMomentMail => {
                this.mailHub.server.setMailContactMomentAsCreated(window.user.id, mail.externalId).then(() => {
                    this.emit({ type: 'MailContactMomentCreatedEvent', mailId: mail.externalId });
                });
            });
        }

        public resetMailStatus(mailId: string): IPromise<any> {
            return this.mailHub.server.resetMailStatus(window.user.id, mailId).then(mail => {
                this.emit({ type: 'MailStatusResetEvent', mailId });
                this.dropFromPickedMails(mailId);
            });
        }

        public createPreviewUrl(mailId: string): string {
            if (mailId.length !== 36) {
                const encodedId = encodeURIComponent(mailId);
                return Config.makeAbsoluteApiUrl('/api/v1/mailhandling/preview/?id=' + encodedId);
            } else {
                return Config.makeAbsoluteApiUrl('/api/v1/mailhandling/databasepreview/?id=' + mailId);
            }
        }

        public sendEmail(emailAddress: string, subject: string, body: string): IPromise<any> {
            return this.mailResource.send({ emailAddress, subject, body }).$promise;
        }

        public replyToEmail(mailId: string, body: string, conversationId: Guid): IPromise<any> {
            return this.mailResource.reply({ mailId, body, conversationId }).$promise;
        }

        public getConversationById(guid: Guid) {
            return this.mailResource.getConversationById({ id: guid }).$promise;
        }

        public linkConversation(
            mailId: string,
            customerId: System.Guid,
            customerName: string,
            salutation: string
        ): IPromise<any> {
            return this.mailHub.server
                .linkMail(window.user.id, mailId, customerId, customerName, salutation)
                .then(() => {
                    this.emit({
                        type: 'MailLinkedEvent',
                        mailId,
                        customerId,
                        customerName
                    });
                });
        }

        public unlinkConversation(mailId: string): IPromise<any> {
            return this.mailHub.server.unlinkMail(window.user.id, mailId).then(() => {
                this.emit({ type: 'MailUnlinkedEvent', mailId });
            });
        }

        public selectFaq(mail: MailModel, faqQuestion: string) {
            return this.mailHub.server.setFaqQuestion(window.user.id, mail.externalId, faqQuestion).then(() => {
                this.emit({ type: 'MailFaqSelectedEvent', faqQuestion });
            });
        }

        public mailBarMenuEvent(mailId: string, isMenuOpened: boolean) {
            this.emit({ type: 'MailBarMenuEvent', mailId, isMenuOpened });
        }

        public finishMailReply(mailId: Guid) {
            this.mailHub.server.finishMailReply(window.user.id, mailId);
        }

        public startMailReply(mailId: Guid) {
            this.mailHub.server.startMailReply(window.user.id, mailId);
        }

        public checkForOngoingReply(mailId: Guid) {
            return this.mailHub.server.checkForOngoingReply(mailId);
        }

        public getReplyDraft(operatorId: Guid, mailId: Guid): IPromise<any> {
            return this.mailResource.getReplyDraft({ operatorId, mailId }).$promise;
        }
        public isMessageAvailableInEmailServer(externalMailId: string) {
            return this.mailResource.isMessageAvailableInEmailServer({ id: externalMailId });
        }

        private refreshDashboardMailList(mailId: string): void {
            const state = this.mailStore.getState();

            const isInList = state && state.list && state.list.mails.filter(m => m.externalId === mailId).length > 0;
            if (isInList) this.emit({ type: 'DashboardMailsMailRemovedEvent', mailId });

            const selectedMail = state && state.details && state.details.mail;
            const isSelected = selectedMail && selectedMail.externalId === mailId;
            if (isSelected) this.selectMail(mailId);
        }

        private dropFromPickedMails(id: string) {
            this.emit({ type: 'PickedMailsRemovedEvent', mailId: id });
        }
    }

    const notNullOrUndefined = x => x !== null && x !== undefined;
    const hasMailHandlingPermissions = x => {
        return x && x.permissions && x.permissions.handleIncomingMails;
    };

    /*    session$
            .map(x => x && x.user)
            .filter(notNullOrUndefined)
            .filter(hasMailHandlingPermissions)
            .take(1)
            .subscribe(() => {*/
    angular.module('MailProcessing').service('MailProcessingService', MailProcessingService);
    //});
}
