namespace Umbrella.MailProcessing {
    @Pipe('MailProcessing', 'mailAttachmentTypeToString')
    class MailAttachmentTypeToStringFilter {
        private translations = {
            Unknown: 'Onbekend',
            Image: 'Afbeelding',
            Document: 'Document',
            Media: 'Media',
            Other: 'Overig'
        };
        public transform = (type: MailAttachmentType): string => {
            return this.translations[MailAttachmentType[type]];
        }
    }
}
