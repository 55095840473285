/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    @Pipe('Umbrella', 'phonetype')
    class PhoneTypeFilter {
        public transform(stringType: string | number) {
            switch (stringType) {
                case 'Home':
                case 0:
                    return 'Thuis';
                case 'Mobile':
                case 1:
                    return 'Mobiel';
                case 'Work':
                case 2:
                    return 'Werk';
                default:
                    return '';
            }
        }
    }
}
