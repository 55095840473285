/// <reference path="../../MailProcessing/MailProcessingStore.ts" />

namespace Umbrella.MailProcessing {
    import MailProcessingStore = Umbrella.MailProcessing.MailProcessingStore;
    import MailProcessingService = Umbrella.MailProcessing.MailProcessingService;

    @Component('MailProcessing', {
        selector: 'mail-availability-controls',
        templateUrl:
            '/Top/MailAvailabilityControlsComponent/MailAvailabilityControls.html',
        bindings: {
            mailProcessingInfo: '<'
        }
    })
    @Inject('MailProcessingService', 'ToastMessageService')
    class MailAvailabilityControlsComponent {
        public collapsed = false;
        private mailHandlingIsEnabledForCurrentUser =
            window.user.permissions.handleIncomingMails;
        public mailProcessingInfo: Umbrella.MailProcessing.MailProcessingState;
        private mailProcessingInfoSub: Rx.IDisposable;
        public mailIconClass = 'grijs';
        public mailIconTooltip = ' ';

        constructor(
            private mailService: MailProcessingService,
            private toastMessageService: ToastMessageService
        ) {}

        $onChanges(bindings: {
            mailProcessingInfo: IBindingChange<
                Umbrella.MailProcessing.MailProcessingState
            >;
        })
        {
            if ( bindings.mailProcessingInfo && bindings.mailProcessingInfo.currentValue )
            {
                const state = bindings.mailProcessingInfo.currentValue;

                if (
                    !state.enabled ||
                    !this.mailHandlingIsEnabledForCurrentUser
                )
                {
                    this.mailIconClass = 'grijs';
                    this.mailIconTooltip = 'E-mail niet beschikbaar';
                }
                else if (state.registered)
                        {
                            this.mailIconClass = 'groen';
                            this.mailIconTooltip = 'Afmelden voor e-mail';
                        }
                        else
                        {
                            this.mailIconClass = 'blauw';
                            this.mailIconTooltip = 'Aanmelden voor e-mail';
                        }
            }
            else // tak
            {
                this.mailIconClass = 'grijs';
                this.mailIconTooltip = 'E-mail niet beschikbaar';
            }
        }

        public show(): void {
            if (
                !this.mailProcessingInfo ||
                !this.mailProcessingInfo.enabled ||
                !this.mailHandlingIsEnabledForCurrentUser
            ) {
                return;
            }

            this.collapsed = true;
        }

        public hide(): void {
            this.collapsed = false;
        }

        public toggleMail(value: boolean) {
            if (value) {
                this.mailService
                    .registerOperator()
                    .catch(() =>
                        this.toastMessageService.error(
                            'Fout opgetreden tijdens aanmelden voor e-mail!'
                        )
                    ); // () => this.toastMessageService.success('Succesvol aangemeld')
            } else {
                this.mailService
                    .deregisterOperator()
                    .catch(() =>
                        this.toastMessageService.error(
                            'Fout opgetreden tijdens afmelden voor e-mail!'
                        )
                    ); // () => this.toastMessageService.success('Succesvol afgemeld')
            }
        }
    }
}
