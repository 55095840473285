/// <reference path="../../BaseStoreService.ts"/>
/// <reference path="../../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../../Helpers.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import ITaskResourceClass = Modules.Tasks.ITaskResourceClass;
    import TaskTypeModel = Modules.Tasks.TaskTypeModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import IFunctionGroupResourceClass = Umbrella.Modules.Colleagues.IFunctionGroupResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import SelectedColleagueOrFunctiongroup = Umbrella.Modules.SelectedColleagueOrFunctiongroup;
    import TaskOverviewStateParams = Umbrella.TaskHandling.Overview.TaskOverviewStateParams;
    import FunctionGroupModel = Umbrella.Modules.Knowledgebase.FunctionGroupModel;
    import TaskOverviewItemModel = Modules.Tasks.TaskOverviewItemModel;
    import TaskCompleteByModel = Modules.Tasks.TaskCompleteByModel;
    import GetEnumValueAsNumber = Helpers.GetEnumValueAsNumber;
    import TaakStatus = Umbrella.Modules.Tasks.TaakStatus;
    import TaskService = Umbrella.Modules.Tasks.TaskService;

    export const showTaskDetailView$ = new Rx.Subject<boolean>();

    const DEFAULT_PAGESIZE = 9;
    const notNull = x => x !== null;

    @Service('TaskHandling', 'TaskOverviewService')
    @Inject(
        '$q',
        '$location',
        'TaskOverviewStore',
        'TaskResource',
        'ColleagueResource',
        'FunctionGroupResource',
        'LocalStorageService',
        'TaskService'
    )
    export class TaskOverviewService extends BaseStoreService<Overview.State, TaskOverviewEvent, TaskOverviewStore> {
        public showTaskDetailView: boolean;

        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            store: TaskOverviewStore,
            private taskResource: ITaskResourceClass,
            private colleagueResource: IColleagueResourceClass,
            private functionGroupResource: IFunctionGroupResourceClass,
            private localStorageService: LocalStorageService,
            private taskService: TaskService
        ) {
            super(store);
            this.observeTaskUpdates();
        }

        public ensureLoaded(): void {
            this.load();
        }

        public setDisplayTaskDetailView(showTaskDetailView: boolean) {
            if (
                (showTaskDetailView !== null && window.device.mobile()) ||
                window.device.surface() ||
                window.device.tablet()
            ) {
                this.showTaskDetailView = showTaskDetailView;
                showTaskDetailView$.onNext(showTaskDetailView);
            }
            return this.showTaskDetailView;
        }

        public async load(pageSize = DEFAULT_PAGESIZE) {
            const state = this.getState();
            const filters = state && state.filters;

            return await this.getTasks(pageSize, filters);
        }

        public addTaskToList(task: TaskModel.Detailed): void {
            if (!task) return;
            if (!this.taskPassesCurrentFilters(task)) return;

            const state = this.getState();
            const list = state && state.tasks;
            if (!state || !list || !list.items) {
                this.load();
                return;
            }

            if (
                !task.ends ||
                (list.items.length >= DEFAULT_PAGESIZE &&
                    new Date(task.ends).getTime() > new Date(list.items[list.items.length - 1].ends).getTime())
            )
                return;

            list.items.push(this.taskModelDetailedToTaskOverviewItemModel(task));
            const modifiedTaskItems = list.items.sort(
                (x1, x2) => new Date(x1.ends).getTime() - new Date(x2.ends).getTime()
            );

            this.emit({
                type: 'TasksModifiedEvent',
                modifiedTasks: {
                    ...list,
                    ...modifiedTaskItems,
                    total: ++list.total
                }
            });
        }

        private taskModelDetailedToTaskOverviewItemModel(task: TaskModel.Detailed): TaskOverviewItemModel {
            const completeBy: TaskCompleteByModel = {
                id: task.completeBy.id,
                name: task.completeBy.name,
                type: task.completeBy.type
            };

            const faq: Umbrella.Modules.Tasks.TaskOverviewItemModel.FaqDto = {
                question: task.case ? task.case.title : ''
            };

            const mappedTask: TaskOverviewItemModel = {
                completeBy,
                created: task.created,
                description: task.description,
                discussionCount: task.totalDiscussions,
                ends: task.ends,
                faq,
                id: task.id,
                lastModified: task.lastModified,
                relatedPerson: task.relatedPerson,
                status: task.status,
                subject: task.subject,
                taskType: task.taskType,
                unitId: task.unitId,
                complexId: task.complexId
            };

            return mappedTask;
        }

        public select(id: System.Guid) {
            const state = this.getState();
            if (state && state.selectedTask && state.selectedTask.id === id) return;

            this.emit({ type: 'TaskDetailsLoadingEvent' });

            const selectedTask =
                state && state.tasks && state.tasks.items && state.tasks.items.filter(t => t.id === id)[0];
            if (selectedTask)
                this.emit({
                    type: 'TaskDetailsLoadedEvent',
                    task: selectedTask,
                    completedBy: null
                });

            this.loadMoreDetails(id);
            this.loadActivities(id);
        }

        public loadMoreDetails(id?: System.Guid): void {
            const state = this.getState();
            if (!id && (!state || !state.selectedTask)) return;

            this.taskResource
                .getById({ id: id || state.selectedTask.id })
                .$promise.then(task => this.updateSelectedTask(task));
        }

        public update(task: TaskModel.Detailed): ng.IPromise<TaskModel.Detailed> {
            const promise = this.taskResource.update(task).$promise;

            promise.then(task => {
                if (this.isSelectedTask(task)) this.updateSelectedTask(task);

                this.updateListWhenContainingTask(task);
            });

            return promise;
        }

        public loadActivities(taskId: System.Guid) {
            this.emit({ type: 'TaskActivitiesLoadingEvent' });
            this.taskResource.getHistory({ id: taskId, page: 0, pageSize: 255 }, data => {
                this.emit({
                    type: 'TaskActivitiesLoadedEvent',
                    activities: data.items
                });
            });
        }

        public pickup(): ng.IPromise<TaskModel> {
            const state = this.getState();
            if (!state || !state.selectedTask) return this.$q.reject('No task selected yet.');

            const promise = this.taskResource.pickUp({
                id: state.selectedTask.id
            }).$promise;

            promise.then(task => {
                this.updateListWhenContainingTask(task);
                if (this.isSelectedTask(task)) this.updateSelectedTask(task);

                // Necessary to load the detailed model (case + contractId), otherwise the inspection buttons aren't displayed
                this.loadMoreDetails(task.id);
            });

            return promise;
        }

        public finish(description: string): ng.IPromise<TaskModel> {
            const state = this.getState();
            if (!state || !state.selectedTask) return this.$q.reject('No task selected yet.');

            const promise = this.taskResource.finish({ id: state.selectedTask.id }, description).$promise;

            promise.then(task => {
                this.removeTaskFromList(task);
                if (this.isSelectedTask(task)) this.updateSelectedTask(task);
            });

            return promise;
        }

        public async finishedViaCaseFlow(id: System.Guid) {
            const state = this.getState();
            var task = await this.taskResource.getById({ id: id }).$promise;

            this.removeTaskFromList(task);
            if (this.isSelectedTask(task)) this.updateSelectedTask(task);
        }

        public reject(reason: string): ng.IPromise<TaskModel> {
            const state = this.getState();
            if (!state || !state.selectedTask) return this.$q.reject('No task selected yet.');

            const promise = this.taskResource.reject({ id: state.selectedTask.id }, reason).$promise;

            promise.then(task => {
                this.updateListWhenContainingTask(task);
                if (this.isSelectedTask(task)) this.updateSelectedTask(task);
            });

            return promise;
        }

        public delete(reason: string): ng.IPromise<TaskModel> {
            const state = this.getState();
            if (!state || !state.selectedTask) return this.$q.reject('No task selected yet.');

            const promise = this.taskResource.delete({ id: state.selectedTask.id }, reason).$promise;

            promise.then(task => {
                if (this.isSelectedTask(task)) this.updateSelectedTask(task);

                this.removeTaskFromList(task);
            });

            return promise;
        }

        public async reply(message: string): Promise<void> {
            const state = this.getState();
            if (!state || !state.selectedTask) return this.$q.reject('No task selected yet.');

            const promise = await this.taskResource.discuss({ id: state.selectedTask.id }, message).$promise;

            this.addTaskDiscussionActivity(state.selectedTask.id, message);

            return promise;
        }

        public filterByQuery(query = '') {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                query
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public filterByStatus(status = '') {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                status
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public filterByDeadline(deadline = '') {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                deadline
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public filterByTaskTypes(taskTypes: string[]) {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                taskTypes
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public filterByCreatedBy(colleagueOrFunctiongroup: SelectedColleagueOrFunctiongroup) {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                createdBy: colleagueOrFunctiongroup
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public filterByAssignedTo(colleagueOrFunctiongroup: SelectedColleagueOrFunctiongroup) {
            const state = this.getState();
            if (!state) return;

            const filters = {
                ...state.filters,
                assignedTo: colleagueOrFunctiongroup
            };

            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters);
        }

        public loadTaskTypes(): ng.IPromise<TaskTypeModel[]> {
            const state = this.getState();
            if (state && state.taskTypes) return this.$q.resolve(state.taskTypes);

            return this.taskResource.queryAllTypesExceptCaseFlow().$promise;
        }

        public async filterByStateParams(params: TaskOverviewStateParams): Promise<void> {
            const propertySet = !!(
                (params.page && params.page > 0) ||
                params.query ||
                params.status ||
                params.deadline ||
                params.taskTypes ||
                params.createdById ||
                params.assignedToId
            );

            if (!propertySet) return;

            const state = this.getState();
            if (!state) return;

            const filters = await this.mapStateParamsToFilterValues(params);
            if (JSON.stringify(state.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, false);
        }

        private async getTasks(pageSize = DEFAULT_PAGESIZE, filters: Overview.TaskOverviewFilters) {
            const state = this.getState();
            const tasksLoadedCount = state && state.tasks && state.tasks.items ? state.tasks.items.length : 0;
            const page = Math.floor(tasksLoadedCount / pageSize);

            this.emit({ type: 'TasksLoadingEvent', page, pageSize });

            await this.taskResource
                .getTasks({
                    page,
                    pageSize,
                    searchQuery: (filters && filters.query) || '',
                    status: (filters && filters.status) || '',
                    completion: (filters && filters.deadline) || '',
                    taskTypes: (filters && filters.taskTypes) || [],
                    createdById: (filters && filters.createdBy && filters.createdBy.id) || null,
                    completeById: (filters && filters.assignedTo && filters.assignedTo.id) || null
                })
                .$promise.then(tasks =>
                    this.emit({
                        type: 'TasksLoadedEvent',
                        tasks: this.filterTasksAlreadyInState(tasks)
                    })
                )
                .catch(error => this.emit({ type: 'TasksLoadFailedEvent', error }));
        }

        private filterTasksAlreadyInState(tasksToAdd: PagedItemsModel<Modules.Tasks.TaskOverviewItemModel>) {
            const state = this.getState();
            if (!state || !state.tasks || !state.tasks.items || state.tasks.items.length <= 0) {
                return tasksToAdd;
            }

            tasksToAdd.items = tasksToAdd.items.filter(x => state.tasks.items.every(t => t.id !== x.id));
            return tasksToAdd;
        }

        private updateListWhenContainingTask(task: TaskModel): void {
            const state = this.getState();
            const taskInList = state && state.tasks && state.tasks.items.filter(t => t.id === task.id)[0];

            if (!taskInList) return;

            if (!this.taskPassesCurrentFilters(task)) {
                this.removeTaskFromList(task);
                return;
            }

            const taskOverviewModel: TaskOverviewItemModel = {
                ...taskInList,
                status: task.status,
                description: task.description,
                completeBy: task.completeBy,
                ends: task.ends,
                lastModified: task.lastModified,
                relatedPerson: task.relatedPerson,
                subject: task.subject,
                taskType: task.taskType
            };

            this.emit({
                type: 'TasksItemModifiedEvent',
                task: taskOverviewModel
            });
        }

        private taskPassesCurrentFilters(task: TaskModel): boolean {
            const state = this.getState();
            const filters = state && state.filters;

            if (!task) return false;

            if (filters && filters.status && GetEnumValueAsNumber(TaakStatus, filters.status) !== task.status)
                return false;
            if (filters && filters.assignedTo && task.completeBy && task.completeBy.id !== filters.assignedTo.id)
                return false;

            if (!filters || !filters.status) {
                if (!this.taskService.isAssignedToMeOrOneOfMyFunctionGroups(task, window.user)) return false;

                const isNewAssignedOrRejectedTask =
                    task.status === TaakStatus.Nieuw ||
                    task.status.toString() === 'Nieuw' ||
                    task.status === TaakStatus.Opgepakt ||
                    task.status.toString() === 'Opgepakt' ||
                    task.status === TaakStatus.Geweigerd ||
                    task.status.toString() === 'Geweigerd';
                if (!isNewAssignedOrRejectedTask) return false;
            }

            return true;
        }

        private removeTaskFromList(task: TaskModel): void {
            const state = this.getState();
            const isTaskInList = state && state.tasks && state.tasks.items.filter(t => t.id === task.id).length > 0;

            if (isTaskInList) {
                this.emit({
                    type: 'TasksModifiedEvent',
                    modifiedTasks: {
                        ...state.tasks,
                        items: state.tasks.items.filter(t => t.id !== task.id),
                        total: state.tasks.total - 1
                    }
                });
            }
        }

        private addTaskDiscussionActivity(taskId: System.Guid, message: string): void {
            const activity = {
                message,
                startTime: new Date().toISOString(),
                colleague: window.user,
                type: 'TaakDiscussieActivity'
            };
            this.emit({ type: 'NewTaskActivityEvent', activity });
        }

        private updateSelectedTask = (task: TaskModel | TaskModel.Detailed) => {
            const hasCase = task => task && task.case;

            if (task && hasCase(task)) {
                const caseId = (<TaskModel.Detailed>task).case.id;
                this.loadLinkedTasks(task.id, caseId);
            }

            if (task.completedById) {
                this.colleagueResource.getById({ id: task.completedById }).$promise.then(colleague => {
                    this.emit({
                        type: 'TaskDetailsLoadedEvent',
                        task,
                        completedBy: colleague
                    });
                });
            } else
                this.emit({
                    type: 'TaskDetailsLoadedEvent',
                    task,
                    completedBy: null
                });
        };

        private isSelectedTask = (task: TaskModel) => {
            const state = this.getState();
            return state && state.selectedTask && state.selectedTask.id === task.id;
        };

        private observeTaskUpdates(): void {
            taskHubOnCreated$.filter(notNull).subscribe(task => {
                this.addTaskToList(task);
            });

            taskHubOnUpdated$.filter(notNull).subscribe(task => {
                if (this.isSelectedTask(task)) {
                    this.loadActivities(task.id);
                    this.updateSelectedTask(task);
                }

                this.updateListWhenContainingTask(task);
            });

            taskHubOnDeleted$.filter(notNull).subscribe(task => {
                this.removeTaskFromList(task);
            });

            taskHubOnActivitiesUpdated$
                .filter(notNull)
                .filter(this.isSelectedTask)
                .subscribe(task => {
                    this.loadActivities(task.id);
                });
        }

        private storeFilterValues(filterValues: Overview.TaskOverviewFilters): void {
            this.localStorageService.store('TaskOverviewFilters', JSON.stringify(filterValues));
        }

        public initializeFilters(): void {
            this.updateFilters(this.generateFiltersQuery());
        }

        public generateFiltersQuery(): Overview.TaskOverviewFilters {
            const storedFilterValues = this.localStorageService.get('TaskOverviewFilters');
            if (storedFilterValues && storedFilterValues.length) {
                return JSON.parse(storedFilterValues);
            }

            const state = this.getState();
            const filters = state && state.filters;
            const emptyFilters = {
                query: '',
                taskTypes: [],
                status: '',
                deadline: '',
                createdBy: null,
                assignedTo: null
            };

            if (!filters) {
                return emptyFilters;
            }

            return { ...emptyFilters, ...filters };
        }

        private updateFilters(filters: Overview.TaskOverviewFilters, storeFilters = true): void {
            const state = this.getState();
            const stateFilters = state && state.filters;

            if (!stateFilters || JSON.stringify(stateFilters) !== JSON.stringify(filters)) {
                if (storeFilters) this.storeFilterValues(filters);
                this.emit({ type: 'TaskOverviewFiltersUpdatedEvent', filters });
            }

            this.updateFiltersUrlQuery(filters);
        }

        public updateFiltersUrlQuery(filterValues: Overview.TaskOverviewFilters): void {
            const filtersQuery = {
                query: filterValues.query,
                status: filterValues.status,
                deadline: filterValues.deadline,
                taskTypes: filterValues.taskTypes,
                assignedToId: (filterValues.assignedTo && filterValues.assignedTo.id) || null,
                createdById: (filterValues.createdBy && filterValues.createdBy.id) || null
            };
            const currentQuery = this.$location.search();
            this.$location.search({ ...currentQuery, ...filtersQuery });
            this.ensureLoaded();
        }

        private async loadLinkedTasks(taskId: System.Guid, caseId: System.Guid): Promise<void> {
            this.emit({ type: 'LinkedTasksLoadingEvent' });

            const allTasks = await this.taskResource.getLinkedTasks({
                id: taskId
            }).$promise;
            const linkedTasks = allTasks ? allTasks.filter(x => x.id !== taskId) : [];

            this.emit({ type: 'LinkedTasksLoadedEvent', linkedTasks });
        }

        private async mapStateParamsToFilterValues(
            params: TaskOverviewStateParams
        ): Promise<Overview.TaskOverviewFilters> {
            const assignedTo: any =
                !!params.assignedToId && (await this.getColleagueOrFunctionGroup(params.assignedToId));
            const createdBy: any = !!params.createdById && (await this.getColleagueOrFunctionGroup(params.createdById));

            return {
                query: params.query,
                status: params.status,
                deadline: params.deadline,
                taskTypes: params.taskTypes,
                assignedTo: assignedTo && {
                    id: assignedTo.id,
                    name: assignedTo.name || assignedTo.title,
                    department: assignedTo.department || '',
                    type: 'Colleagues'
                },
                createdBy: createdBy && {
                    id: createdBy.id,
                    name: createdBy.name || createdBy.title,
                    department: createdBy.department || '',
                    type: 'Colleagues'
                }
            };
        }

        private async getColleagueOrFunctionGroup(
            id: System.Guid
        ): Promise<ColleagueModel.Detailed | FunctionGroupModel> {
            try {
                const colleague = await this.colleagueResource.getById({ id }).$promise;
                return colleague;
            } catch (_) {
                const functiongroup = await this.functionGroupResource.getById({
                    id
                }).$promise;
                return functiongroup;
            }
        }
    }
}
