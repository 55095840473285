﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    unitActivityHub: Umbrella.CustomerService.UnitCard.Activities.IUnitActivityHubProxy;
}

namespace Umbrella.CustomerService.UnitCard.Activities {
    export interface IUnitActivityHubProxy extends HubProxy {
        client: IUnitActivityHubClient;
        server: IUnitActivityHubServer;
    }

    export interface IUnitActivityHubClient {
        updated(unitId: System.Guid);
        started(unitId: System.Guid);
    }
    export interface IUnitActivityHubServer {
        startJitUpdate(uintId: System.Guid);
    }

    angular
        .module('CustomerService')
        .factory('UnitActivityHub', () => $.connection.unitActivityHub);

    export const unitActivityHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const unitActivityHubOnStarted$ = new Rx.Subject<System.Guid>();

    Modules.onSignalRInitialized(() => {
        $.connection.unitActivityHub.client.updated = model => {
            unitActivityHubOnUpdated$.onNext(model);
        };

        $.connection.unitActivityHub.client.started = unitId => {
            unitActivityHubOnStarted$.onNext(unitId);
        };
    });
}
