namespace Umbrella.MailProcessing {
    @Component('MailProcessing', {
        selector: 'mail-processing-preview',
        templateUrl: '/MailProcessing/MailProcessingPreviewComponent/MailProcessingPreview.html',
        bindings: {
            mail: '<'
        }
    })
    @Inject('MailProcessingService')
    export class MailProcessingPreviewComponent {
        public mail: MailModel;
        public mailBodyUrl: string;

        constructor(private mailService: MailProcessingService) {}

        public $onInit() {
            if (this.mail && this.mail.externalId) {
                this.mailBodyUrl = this.mailService.createPreviewUrl(this.mail.externalId);
            }
        }
    }
}
