namespace Umbrella.Modules {
    export interface IAgentFacetService {
        isJitUpdateEnabled(): boolean;
        isErpFieldUpdateable(field: string): boolean;
    }

    @Service('Umbrella', 'AgentFacetService')
    export class AgentFacetService implements IAgentFacetService {
        public isJitUpdateEnabled(): boolean {
            return this.erpAvailable() && this.getErpFacetConfiguration().isJitUpdateEnabled;
        }

        public hasUpdateablePersonFields(): boolean {
            return (
                this.isErpFieldUpdateable('personInitials') ||
                this.isErpFieldUpdateable('personFirstName') ||
                this.isErpFieldUpdateable('personInsertion') ||
                this.isErpFieldUpdateable('personLastName') ||
                this.isErpFieldUpdateable('personBirthDate') ||
                this.isErpFieldUpdateable('personGender') ||
                this.isErpFieldUpdateable('postalAddress')
            );
        }

        public hasUpdateableCompanyFields(): boolean {
            return this.isErpFieldUpdateable('companyName') || this.isErpFieldUpdateable('postalAddress');
        }

        public hasUpdateableRegistrationFields(): boolean {
            return this.isErpFieldUpdateable('grossIncome') || this.isErpFieldUpdateable('numberOfPeople');
        }

        public isErpFieldUpdateable(field: string): boolean {
            if (!this.isAuthorizedToUpdateField(field)) return false;
            if (this.isInDebugMode() || !this.erpRegistered()) return true;
            if (!this.erpAvailable()) return false;

            const erp = this.getErpFacetConfiguration();
            return erp && erp.updateableRoleFields && erp.updateableRoleFields[field];
        }

        private isAuthorizedToUpdateField(fieldName: string): boolean {
            switch (fieldName) {
                case 'postalAddress':
                    return window.user.permissions.updateContactPostalAddress;
                case 'phoneNumber':
                    return window.user.permissions.updateContactPhoneNumbers;
                case 'phoneNumber2':
                    return window.user.permissions.updateContactPhoneNumbers;
                case 'email':
                    return window.user.permissions.updateContactEmailAddresses;
                case 'personBirthDate':
                    return window.user.permissions.updateBirthDate;
                case 'personGender':
                    return window.user.permissions.updateGender;
                case 'grossIncome':
                    return window.user.permissions.registrationWrite;
                case 'numberOfPeople':
                    return window.user.permissions.registrationWrite;
                default:
                    return window.user.permissions.customerWrite;
            }
        }

        private erpRegistered(): boolean {
            const erp = this.getErpFacetConfiguration();
            return erp !== null && erp != undefined;
        }

        private erpAvailable(): boolean {
            const erp = this.getErpFacetConfiguration();
            return erp && erp.available;
        }

        private getErpFacetConfiguration(): any {
            return window.config && window.config.facets && window.config.facets.erp && window.config.facets.erp[0];
        }

        private isInDebugMode(): boolean {
            return window.config.configuration === 'DEBUG';
        }
    }
}
