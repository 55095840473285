namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    @Component('MailProcessing', {
        selector: 'mail-processing-body-view',
        templateUrl:
            '/MailProcessing/MailProcessingBodyViewComponent/MailProcessingBodyView.html',
        bindings: {
            mailId: '<'
        }
    })
    @Inject('MailProcessingService')
    export class MailProcessingBodyViewComponent {
        public mailId: string | Guid;
        public mailBodyUrl: string;
        public isLoading: boolean;

        constructor(private mailService: MailProcessingService) {}

        public $onInit() {
            this.isLoading = true;
            if (this.mailId) {
                this.mailBodyUrl = this.mailService.createPreviewUrl(<string>(
                    this.mailId
                ));
            }
        }

        public loadingDone() {
            this.isLoading = false;
        }
    }
}
