﻿/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    personHub: Umbrella.CustomerService.CustomerCard.IPersonHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard {
    export interface IPersonHubProxy extends HubProxy {
        client: IPersonHubClient;
        server: IPersonHubServer;
    }

    export const personHubOnUpdated$ = new Rx.Subject<PersonModel>();
    export const personHubOnStarted$ = new Rx.Subject<System.Guid>();

    export interface IPersonHubClient {
        updated(person: PersonModel);
        started(personId: System.Guid);
    }

    export interface IPersonHubServer {
        startJitUpdate(personId: System.Guid): JQueryPromise<UpdateStatus>;
    }

    angular
        .module('CustomerService')
        .factory('PersonHub', () => $.connection.personHub);

    Modules.onSignalRInitialized(() => {
        $.connection.personHub.client.updated = model => {
            personHubOnUpdated$.onNext(model);
        };

        $.connection.personHub.client.started = personId => {
            personHubOnStarted$.onNext(personId);
        };
    });
}
