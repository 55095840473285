﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Repairs {
    import AnswerModel = SelfService.Models.AnswerModel;

    export interface IAnswerResource extends AnswerModel.Detailed {}

    export interface IGetAnswersParams {
        parentId?: System.Guid;
        housingTypeId?: System.Guid;
    }

    export interface IAnswerResourceClass {
        getById(
            params: IdRequestParams
        ): ng.resource.IResource<AnswerModel.Detailed>;
        getAnswers(
            params: IGetAnswersParams,
            callback?: (answer: AnswerModel.Detailed[]) => void
        ): ng.resource.IResource<AnswerModel.Detailed[]>;
    }

    angular.module('Repairs').service('AnswerResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/answer/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'GetAnswerById', id: '@id' }
                    },
                    getAnswers: {
                        method: 'GET',
                        isArray: true,
                        url: '/api/v1/answer/getAnswers/:parentId',
                        params: { parentId: '@parentId' }
                    },
                    getAnswersOfIndex: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'getAnswersOfIndex', index: 0 }
                    }
                }
            )
    ]);
}
