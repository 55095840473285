/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    mailHandlingHub: Umbrella.MailProcessing.MailHandlingHubProxy;
}

namespace Umbrella.MailProcessing {
    import Guid = System.Guid;

    export interface MailProcessingServer {
        register(operatorId: System.Guid);
        deregister(operatorId: System.Guid);
        getMails(page: number, pageSize: number);
        getMailDetails(id: string);
        pickupMail(
            operatorId: System.Guid,
            mailId: string,
            isAnonymous: boolean,
            customerName: string
        );
        linkMail(
            operatorId: System.Guid,
            mailId: string,
            customerId: System.Guid,
            customerName: string,
            salutation: string
        );
        unlinkMail(operatorId: System.Guid, mailId: string);
        setFaqQuestion(operatorId: System.Guid, mailId: string, faqQuestion: string);
        setMailContactMomentAsCreated(operatorId: System.Guid, mailId: string);

        deleteMail(operatorId: System.Guid, mailId: string);
        refuseMail(operatorId: System.Guid, mailId: string);
        completeMail(operatorId: System.Guid, mailId: string);
        resetMailStatus(operatorId: System.Guid, mailId: string);
        startMailReply(operatorId: Guid, mailId: Guid);
        finishMailReply(operatorId: Guid, mailId: Guid);
        checkForOngoingReply(
            mailId: Guid
        ): MailProcessingServerResponse<boolean>;

        getMailsBeingRepliedTo(): MailProcessingServerResponse<{
            data: MailReplyAvailabilityModel[];
        }>;
    }

    export interface MailProcessingServerResponse<T> {
        done(callback: (data: T) => any): MailProcessingServerResponse<T>;
        fail(
            callback: (error: string) => void
        ): MailProcessingServerResponse<string>;
    }
    export interface MailHandlingHubClient {
        init();
        mailPickedUp(operatorId: System.Guid, mailId: string);
        mailHandled(mailId: string);
        mailStatusReset(mailId: string);
        deregisteredOperatorMails(mailIds: string[]);
    }

    export interface MailHandlingHubProxy extends HubProxy {
        server: MailProcessingServer;
        client: MailHandlingHubClient;
    }

    const notNullOrUndefined = x => x !== null && x !== undefined;
    const hasMailHandlingPermissions = x => {
        return x && x.permissions && x.permissions.handleIncomingMails;
    };

    /*session$
        .map(x => x && x.user)
        .filter(notNullOrUndefined)
        .filter(hasMailHandlingPermissions)
        .take(1)
        .subscribe(() => {*/
    angular
        .module('MailProcessing')
        .factory('MailHandlingHub', () => $.connection.mailHandlingHub);
    //});
}
