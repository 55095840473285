/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.MailConversationHandling.Overview {
    angular.module('Mail').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.mail.compose', {
                //TODO
            });
        }
    ]);
}
