/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.MailProcessing {
    angular.module('MailProcessing').directive('iframeOnLoad', [
        () => {
            return {
                restrict: 'A',
                link: (scope, el, attr) => {
                    el.on('load', () => {
                        const functionName = attr.iframeOnLoad;
                        const callback = scope.$eval(functionName);
                        if (callback) {
                            callback();
                        }
                    });
                }
            };
        }
    ]);
}
