/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules {
    export interface IActivityResource
        extends angular.resource.IResource<ActivityModel> {}

    export interface PagedRequestWithIdAndCategoryParams
        extends PagedRequestWithIdParams {
        activityCategory: string;
    }

    interface CaseIdsParams {
        caseIds: string;
    }

    export interface IActivityResourceClass
        extends angular.resource.IResourceClass<IActivityResource> {
        getByRoleId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByColleagueId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByCaseId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByContractId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByContractsOfRoleId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByActivityCategoryAndContractsOfRoleId(
            params: PagedRequestWithIdAndCategoryParams
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getByUnitId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getAll(
            params: ResourceSearchParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getCategories(): ActivityCategoryModel[];
        update(model: ActivityModel): IActivityResource;
        getRepairRequestActivitiesByCaseId(
            params: PagedRequestWithIdParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        getActivityCountForCases(params: CaseIdsParams): angular.resource.IResource<CaseActivityCountModel[]>;
        deleteItem(
            params: IdRequestParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>;
        restoreItem(
            params: IdRequestParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<ActivityModel>>
    }

    angular.module('Umbrella').service('ActivityResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/activity/:action/:id/:subaction',
                {},
                {
                    getByRoleId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'role', roleId: '@id' }
                    },
                    getByColleagueId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'colleague', colleagueId: '@id' }
                    },
                    getByCaseId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'case',
                            caseId: '@id',
                            sortorder: 'desc'
                        }
                    },
                    getByContractId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'contract', contractId: '@id' }
                    },
                    getByContractsOfRoleId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'role',
                            roleId: '@id',
                            subaction: 'contracts'
                        }
                    },
                    getByUnitId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'unit', contractId: '@id' }
                    },
                    getCategories: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'categories' }
                    },
                    getByActivityCategoryAndContractsOfRoleId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'role',
                            roleId: '@id',
                            subaction: 'bycategory'
                        }
                    },
                    getRepairRequestActivitiesByCaseId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'case',
                            caseId: '@id',
                            subaction: 'repairrequests'
                        }
                    },
                    getActivityCountForCases: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'activityCount',
                            caseIds: 'caseIds'
                        }
                    },
                    deleteItem: {
                        url: '/api/v1/activity/:id',
                        method: 'DELETE',
                        isArray: false,
                        params: {
                            id: '@id'
                        }
                    },
                    restoreItem: {
                        url: '/api/v1/activity/:id/:action',
                        method: 'PUT',
                        isArray: false,
                        params: {
                            action: 'restore',
                            id: '@id'
                        }
                    }
                }
            )
    ]);
}
