namespace Umbrella.MailProcessing {
    import Guid = System.Guid;
    import IResource = ng.resource.IResource;

    export interface MailProcessingResource {
        getById(params: { id: string }): ng.resource.IResource<MailModel>;
        getConversationById(params: {
            id: Guid;
        }): ng.resource.IResource<MailModel[]>;
        search(params: {
            page: number;
            pageSize: number;
            query: string;
            status: number;
        }): ng.resource.IResource<PagedItemsModel<MailModel>>;
        send(model: ComposeModel): ng.resource.IResource<MailModel>;
        reply(model: ReplyModel): ng.resource.IResource<MailModel>;
        getReplyDraft(params: {
            operatorId: Guid;
            mailId: Guid;
        }): ng.resource.IResource<MailReplyDraft>;
        save(model: MailModel): any;
        isMessageAvailableInEmailServer(params: {id: string}): ng.resource.IResource<boolean>;
    }

    angular.module('MailProcessing').service('MailProcessingResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/mailHandling/:action/:id',
                {},
                {
                    getById: {
                        url: '/api/v1/mailHandling/:id',
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            page: '@page',
                            pageSize: '@pageSize',
                            query: '@query',
                            status: '@status'
                        }
                    },
                    getConversationById: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'getConversationById', id: '@id' }
                    },
                    send: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'send' }
                    },
                    reply: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'reply' }
                    },
                    getReplyDraft: {
                        url: '/api/v1/mailHandling/:action/:operatorId/:mailId',
                        method: 'GET',
                        isArray: false,
                        params: { action: 'replydraft', operatorId: '@operatorId', mailId: '@mailId' }                        
                    },
                    save: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'save' }
                    },
                    isMessageAvailableInEmailServer: {
                        url: '/api/v1/mailHandling/isMessageAvailableInEmailServer/',
                        method: 'GET',
                        isArray: false,
                        params: { id: encodeURIComponent('@id') }
                    }
                }
            )
    ]);
}
